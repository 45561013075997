
// // // import React, { useState, useEffect } from 'react';
// // // import axios from 'axios';
// // // import Sidebar from './Sidebar';
// // // import styled from 'styled-components';
// // // import { BASE_URL } from '../config/apiConfig';
// // // import Header from './Header'; 
// // // import Redirect from "../../Components/Redirect";
// // // const SidebarStyled = styled.div`
// // //   position: fixed; 
// // //   top: 0;
// // //   left: 0;
// // //   height: 100vh; 
// // //   width: 250px; 
// // //   background-color: #f4f4f4;
// // //   overflow-y: auto; 
// // //   z-index: 1000; 
// // // `;
// // // const MainContent = styled.div`
// // //   margin-left: 250px; 
// // //   padding: 20px;
// // //   font-family: Arial, sans-serif;
// // // `;
// // // const Table = styled.table`
// // //   width: 100%;
// // //   border-collapse: collapse;
// // // `;
// // // const Th = styled.th`
// // //   padding: 10px;
// // //   border: 1px solid #ddd;
// // //   text-align: left;
// // //   background-color: #f4f4f4;
// // // `;
// // // const Td = styled.td`
// // //   padding: 10px;
// // //   border: 1px solid #ddd;
// // //   text-align: left;
// // // `;
// // // const Tr = styled.tr`
// // //   &:nth-child(even) {
// // //     background-color: #f9f9f9;
// // //   }
// // // `;
// // // const DoctorsList = () => {
// // //   const [doctors, setDoctors] = useState([]);
// // //   const [loading, setLoading] = useState(true); 
// // //   const [error, setError] = useState(null); 
// // //   const jwtToken = sessionStorage.getItem('jwtToken');
// // //   useEffect(() => {
// // //     const fetchDoctors = async () => {
// // //       if (!jwtToken) {
// // //         setError('No JWT token available for authentication');
// // //         setLoading(false);
// // //         return;
// // //       }

// // //       try {
// // //         const response = await axios.get(`${BASE_URL}/user/doctors`, {
// // //           headers: {
// // //             'Auth': `Bearer ${jwtToken}`
// // //           }
// // //         });
// // //         setDoctors(response.data);
// // //       } catch (error) {
// // //         setError('Error fetching doctors');
// // //       } finally {
// // //         setLoading(false);
// // //       }
// // //     };

// // //     fetchDoctors();
// // //   }, [jwtToken]); 

// // //   if (loading) {
// // //     return <p>Loading...</p>;
// // //   }

// // //   if (error) {
// // //     return <p>{error}</p>;
// // //   }

// // //   return (
// // //     <div className="doctors-list">
// // //         <Redirect />
// // //       <Header />
// // //       <SidebarStyled>
// // //         <Sidebar />
// // //       </SidebarStyled>
// // //       <MainContent>
// // //         <h1>Doctors List</h1>
// // //         <Table>
// // //           <thead>
// // //             <tr>
// // //               <Th>Name</Th>
// // //               <Th>Email</Th>
// // //               <Th>Mobile No</Th>
// // //               <Th>Specialist</Th>
// // //             </tr>
// // //           </thead>
// // //           <tbody>
// // //             {doctors.map((doctor, index) => (
// // //               <Tr key={index}>
// // //                 <Td>{doctor.name}</Td>
// // //                 <Td>{doctor.email}</Td>
// // //                 <Td>{doctor.mobileNo}</Td>
// // //                 <Td>{doctor.specialist}</Td>
// // //               </Tr>
// // //             ))}
// // //           </tbody>
          
// // //         </Table>
// // //       </MainContent>
// // //     </div>
// // //   );
// // // };

// // // export default DoctorsList;
// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import Sidebar from './Sidebar';
// // import styled from 'styled-components';
// // import { BASE_URL } from '../config/apiConfig';
// // import Header from './Header'; 
// // import Redirect from "../../Components/Redirect";

// // const SidebarStyled = styled.div`
// //   position: fixed; 
// //   top: 0;
// //   left: 0;
// //   height: 100vh; 
// //   width: 250px; 
// //   background-color: #f4f4f4;
// //   overflow-y: auto; 
// //   z-index: 1000; 
// // `;

// // const MainContent = styled.div`
// //   margin-left: 250px; 
// //   padding: 20px;
// //   font-family: Arial, sans-serif;
// // `;

// // const Table = styled.table`
// //   width: 100%;
// //   border-collapse: collapse;
// // `;

// // const Th = styled.th`
// //   padding: 10px;
// //   border: 1px solid #ddd;
// //   text-align: left;
// //   background-color: #f4f4f4;
// // `;

// // const Td = styled.td`
// //   padding: 10px;
// //   border: 1px solid #ddd;
// //   text-align: left;
// // `;

// // const Tr = styled.tr`
// //   &:nth-child(even) {
// //     background-color: #f9f9f9;
// //   }
// // `;

// // const Loading = styled.div`
// //   text-align: center;
// //   padding: 20px;
// //   font-size: 18px;
// // `;

// // const DoctorsList = () => {
// //   const [doctors, setDoctors] = useState([]);
// //   const [loading, setLoading] = useState(true); 
// //   const [error, setError] = useState(null); 
// //   const jwtToken = sessionStorage.getItem('jwtToken');

// //   useEffect(() => {
// //     const fetchDoctors = async () => {
// //       if (!jwtToken) {
// //         setError('No JWT token available for authentication');
// //         setLoading(false);
// //         return;
// //       }

// //       try {
// //         const response = await axios.get(`${BASE_URL}/user/doctors`, {
// //           headers: {
// //             'Auth': `Bearer ${jwtToken}`
// //           }
// //         });
// //         setDoctors(response.data);
// //       } catch (error) {
// //         setError('Error fetching doctors');
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchDoctors();
// //   }, [jwtToken]); 

// //   return (
// //     <div className="doctors-list">
// //       <Redirect />
// //       <Header />
// //       <SidebarStyled>
// //         <Sidebar />
// //       </SidebarStyled>
// //       <MainContent>
// //         <h1>Doctors List</h1>
// //         {loading ? (
// //           <Loading>Loading...</Loading>  // Show loading message only in the data area
// //         ) : error ? (
// //           <p>{error}</p>  // Show error message only in the data area
// //         ) : (
// //           <Table>
// //             <thead>
// //               <tr>
// //                 <Th>Name</Th>
// //                 <Th>Email</Th>
// //                 <Th>Mobile No</Th>
// //                 <Th>Specialist</Th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {doctors.map((doctor, index) => (
// //                 <Tr key={index}>
// //                   <Td>{doctor.name}</Td>
// //                   <Td>{doctor.email}</Td>
// //                   <Td>{doctor.mobileNo}</Td>
// //                   <Td>{doctor.specialist}</Td>
// //                 </Tr>
// //               ))}
// //             </tbody>
// //           </Table>
// //         )}
// //       </MainContent>
// //     </div>
// //   );
// // };

// // export default DoctorsList;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from './Sidebar';
// import styled from 'styled-components';
// import { BASE_URL } from '../config/apiConfig';
// import Header from './Header'; 
// import Redirect from "../../Components/Redirect";

// // Styled Components
// const SidebarStyled = styled.div`
//   position: fixed; 
//   top: 0;
//   left: 0;
//   height: 100vh; 
//   width: 250px; 
//   background-color: #f4f4f4;
//   overflow-y: auto; 
//   z-index: 1000; 
// `;

// const MainContent = styled.div`
//   margin-left: 250px; 
//   padding: 20px;
//   font-family: Arial, sans-serif;
//   background-color: #f8f9fa; /* Optional: background color for the main content */
//   min-height: 100vh; /* Ensures the content takes the full height */
// `;

// const TableContainer = styled.div`
//   max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
//   overflow-y: auto; /* Enable vertical scrolling */
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   margin-top: 20px; /* Margin to separate from heading */
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
// `;

// const Th = styled.th`
//   padding: 10px;
//   border: 1px solid #ddd;
//   text-align: left;
//   background-color: #287b9c; /* Primary color for header */
//   color: white; /* Text color for header */
// `;

// const Td = styled.td`
//   padding: 10px;
//   border: 1px solid #ddd;
//   text-align: left;
// `;

// const Tr = styled.tr`
//   &:nth-child(even) {
//     background-color: #f9f9f9; /* Light grey for even rows */
//   }
// `;

// const Loading = styled.div`
//   text-align: center;
//   padding: 20px;
//   font-size: 18px;
// `;

// const PageHeading = styled.h1`
//   text-align: center;
//   color: #324e6a;
//   font-size: 36px;
//   margin-bottom: 30px;
// `;

// const DoctorsList = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true); 
//   const [error, setError] = useState(null); 
//   const jwtToken = sessionStorage.getItem('jwtToken');

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       if (!jwtToken) {
//         setError('No JWT token available for authentication');
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get(`${BASE_URL}/user/doctors`, {
//           headers: {
//             'Auth': `Bearer ${jwtToken}`
//           }
//         });
//         setDoctors(response.data);
//       } catch (error) {
//         setError('Error fetching doctors');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [jwtToken]); 

//   return (
//     <div className="doctors-list">
//       <Redirect />
//       <Header />
//       <SidebarStyled>
//         <Sidebar />
//       </SidebarStyled>
//       <MainContent>
//         <PageHeading>Doctors List</PageHeading>
//         <TableContainer rowCount={doctors.length}>
//           {loading ? (
//             <Loading>Loading...</Loading>  // Show loading message only in the data area
//           ) : error ? (
//             <p>{error}</p>  // Show error message only in the data area
//           ) : (
//             <Table>
//               <thead>
//                 <tr>
//                   <Th>Name</Th>
//                   <Th>Email</Th>
//                   <Th>Mobile No</Th>
//                   <Th>Specialist</Th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {doctors.map((doctor, index) => (
//                   <Tr key={index}>
//                     <Td>{doctor.name}</Td>
//                     <Td>{doctor.email}</Td>
//                     <Td>{doctor.mobileNo}</Td>
//                     <Td>{doctor.specialist}</Td>
//                   </Tr>
//                 ))}
//               </tbody>
//             </Table>
//           )}
//         </TableContainer>
//       </MainContent>
//     </div>
//   );
// };

// export default DoctorsList;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { BASE_URL } from '../config/apiConfig';
import Header from './Header'; 
import Redirect from "../../Components/Redirect";

// Styled Components
const SidebarStyled = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh; 
  width: 250px; 
  background-color: #f4f4f4;
  overflow-y: auto; 
  z-index: 1000; 
`;

const MainContent = styled.div`
  margin-left: 250px; 
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Optional: background color for the main content */
  min-height: 100vh; /* Ensures the content takes the full height */
`;

const TableContainer = styled.div`
  max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Margin to separate from heading */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #287b9c; /* Primary color for header */
  color: white; /* Text color for header */
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; /* Light grey for even rows */
  }
  &:nth-child(odd) {
    background-color: white; /* White for odd rows */
  }
`;

const EmptyRow = styled(Tr)`
  height: 50px; /* Set height to match filled rows */
  background-color: #f9f9f9; /* Light grey background for empty rows */
`;

const Loading = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
`;

const PageHeading = styled.h1`
  text-align: center;
  color: #324e6a;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const jwtToken = sessionStorage.getItem('jwtToken');

  useEffect(() => {
    const fetchDoctors = async () => {
      if (!jwtToken) {
        setError('No JWT token available for authentication');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${BASE_URL}/user/doctors`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setDoctors(response.data);
      } catch (error) {
        setError('Error fetching doctors');
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, [jwtToken]); 

  // Generate empty rows if doctors.length < 9
  const totalRows = 9;
  const emptyRows = totalRows - doctors.length;

  return (
    <div className="doctors-list">
      <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <PageHeading>Doctors List</PageHeading>
        <TableContainer rowCount={doctors.length || emptyRows}>
          {loading ? (
            <Loading>Loading...</Loading>  // Show loading message only in the data area
          ) : error ? (
            <p>{error}</p>  // Show error message only in the data area
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Mobile No</Th>
                  <Th>Specialist</Th>
                </tr>
              </thead>
              <tbody>
                {doctors.map((doctor, index) => (
                  <Tr key={index}>
                    <Td>{doctor.name}</Td>
                    <Td>{doctor.email}</Td>
                    <Td>{doctor.mobileNo}</Td>
                    <Td>{doctor.specialist}</Td>
                  </Tr>
                ))}
                {/* Add empty rows */}
                {emptyRows > 0 && [...Array(emptyRows)].map((_, index) => (
                  <EmptyRow key={`empty-${index}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </EmptyRow>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </MainContent>
    </div>
  );
};

export default DoctorsList;
