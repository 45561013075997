


import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import Redirect from "../Components/Redirect"; // Import Redirect.js
import Drappontmenthistory from "../Components/Drappontmenthistory";
import Videoheader from "../Components/Video/Videoheader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
// Global styles to reset default margins and paddings
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const AppWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #DCE7EA, #C3E2EB); /* Set linear gradient background */
  z-index: -1;
`;

const ContentWrapper = styled.div`
  padding: 2px; /* Add some padding for better readability */
`;

function Home() {
  return (
    <ContentWrapper>
      <HeaderWrapper>
      <Redirect />
        <Header />
      </HeaderWrapper>
      <Videoheader />
      <Drappontmenthistory />
      <Footer />
      {/* <Vitals /> */}
    </ContentWrapper>
  );
}

// Ensure that Header has no margin or padding
const HeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

function Drappoinment() {
  return (
    <AppWrapper>
      <GlobalStyle />
      <Background />
      <Home />
    </AppWrapper>
  );
}

export default Drappoinment;
