
// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import Sidebar from './Sidebar';
// // import styled from 'styled-components';
// // import Header from './Header'; 
// // import { BASE_URL } from '../config/apiConfig';
// // import Redirect from "../../Components/Redirect";

// // const SidebarStyled = styled.div`
// //   position: fixed;
// //   top: 0;
// //   left: 0;
// //   height: 100vh;
// //   width: 250px;
// //   background-color: #f4f4f4;
// //   overflow-y: auto;
// //   z-index: 1000;
// // `;

// // const MainContent = styled.div`
// //   margin-left: 250px;
// //   padding: 20px;
// //   font-family: Arial, sans-serif;
// // `;

// // const Table = styled.table`
// //   width: 100%;
// //   border-collapse: collapse;
// // `;

// // const Th = styled.th`
// //   padding: 10px;
// //   border: 1px solid #ddd;
// //   text-align: left;
// //   background-color: #f4f4f4;
// // `;

// // const Td = styled.td`
// //   padding: 10px;
// //   border: 1px solid #ddd;
// //   text-align: left;
// // `;

// // const Tr = styled.tr`
// //   &:nth-child(even) {
// //     background-color: #f9f9f9;
// //   }
// // `;

// // const HealthOfficersList = () => {
// //   const [healthOfficers, setHealthOfficers] = useState([]);
// //   const [error, setError] = useState(null);
// //   const jwtToken = sessionStorage.getItem('jwtToken');

// //   // useEffect must always be called unconditionally
// //   useEffect(() => {
// //     if (!jwtToken) {
// //       console.error('No JWT token available for authentication');
// //       setError('No JWT token available for authentication');
// //       return;
// //     }

// //     // Fetch health officers data from API
// //     const fetchHealthOfficers = async () => {
    
// //       try {
// //         const response = await axios.get(`${BASE_URL}/user/health-officer`, {
// //           headers: {
// //             'Auth': `Bearer ${jwtToken}`,
// //           },
// //         });
// //         setHealthOfficers(response.data);
// //       } catch (error) {
// //         console.error('Error fetching health officers:', error);
// //         setError('Error fetching health officers');
// //       }
// //     };

// //     fetchHealthOfficers();
// //   }, [jwtToken]);

// //   if (error) {
// //     return <p>{error}</p>;
// //   }

// //   return (
// //     <div className="health-officers-list">
// //         <Redirect />
// //       <Header />
// //       <SidebarStyled>
// //         <Sidebar />
// //       </SidebarStyled>
// //       <MainContent>
// //         <h1>Health Officers List</h1>
// //         <Table>
// //           <thead>
// //             <tr>
// //               <Th>Name</Th>
// //               <Th>Email</Th>
// //               <Th>Mobile No</Th>
// //               <Th>Specialist</Th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {healthOfficers.map((officer, index) => (
// //               <Tr key={index}>
// //                 <Td>{officer.name}</Td>
// //                 <Td>{officer.email}</Td>
// //                 <Td>{officer.mobileNo}</Td>
// //                 <Td>{officer.specialist}</Td>
// //               </Tr>
// //             ))}
// //           </tbody>
// //         </Table>
// //       </MainContent>
// //     </div>
// //   );
// // };

// // export default HealthOfficersList;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from './Sidebar';
// import styled from 'styled-components';
// import Header from './Header'; 
// import { BASE_URL } from '../config/apiConfig';
// import Redirect from "../../Components/Redirect";

// const SidebarStyled = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 250px;
//   background-color: #f4f4f4;
//   overflow-y: auto;
//   z-index: 1000;
// `;

// const MainContent = styled.div`
//   margin-left: 250px;
//   padding: 20px;
//   font-family: Arial, sans-serif;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
// `;

// const Th = styled.th`
//   padding: 10px;
//   border: 1px solid #ddd;
//   text-align: left;
//   background-color: #f4f4f4;
// `;

// const Td = styled.td`
//   padding: 10px;
//   border: 1px solid #ddd;
//   text-align: left;
// `;

// const Tr = styled.tr`
//   &:nth-child(even) {
//     background-color: #f9f9f9;
//   }
// `;

// const Loading = styled.div`
//   text-align: center;
//   padding: 20px;
//   font-size: 18px;
// `;

// const HealthOfficersList = () => {
//   const [healthOfficers, setHealthOfficers] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);  // For tracking the loading state
//   const jwtToken = sessionStorage.getItem('jwtToken');

//   // useEffect must always be called unconditionally
//   useEffect(() => {
//     if (!jwtToken) {
//       console.error('No JWT token available for authentication');
//       setError('No JWT token available for authentication');
//       return;
//     }

//     // Fetch health officers data from API
//     const fetchHealthOfficers = async () => {
//       setLoading(true);  // Set loading to true when starting to fetch
//       try {
//         const response = await axios.get(`${BASE_URL}/user/health-officer`, {
//           headers: {
//             'Auth': `Bearer ${jwtToken}`,
//           },
//         });
//         setHealthOfficers(response.data);
//         setLoading(false);  // Set loading to false when data is fetched
//       } catch (error) {
//         console.error('Error fetching health officers:', error);
//         setError('Error fetching health officers');
//         setLoading(false);  // Stop loading even if there's an error
//       }
//     };

//     fetchHealthOfficers();
//   }, [jwtToken]);

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <div className="health-officers-list">
//       <Redirect />
//       <Header />
//       <SidebarStyled>
//         <Sidebar />
//       </SidebarStyled>
//       <MainContent>
//         <h1>Health Officers List</h1>
//         {loading ? (
//           <Loading>Loading...</Loading>  // Show loading only in table section
//         ) : (
//           <Table>
//             <thead>
//               <tr>
//                 <Th>Name</Th>
//                 <Th>Email</Th>
//                 <Th>Mobile No</Th>
//                 <Th>Specialist</Th>
//               </tr>
//             </thead>
//             <tbody>
//               {healthOfficers.map((officer, index) => (
//                 <Tr key={index}>
//                   <Td>{officer.name}</Td>
//                   <Td>{officer.email}</Td>
//                   <Td>{officer.mobileNo}</Td>
//                   <Td>{officer.specialist}</Td>
//                 </Tr>
//               ))}
//             </tbody>
//           </Table>
//         )}
//       </MainContent>
//     </div>
//   );
// };

// export default HealthOfficersList;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header'; 
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect";

// Styled Components
const SidebarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #f4f4f4;
  overflow-y: auto;
  z-index: 1000;
`;

const MainContent = styled.div`
  margin-left: 250px;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Optional: background color for the main content */
  min-height: 100vh; /* Ensures the content takes the full height */
`;

const TableContainer = styled.div`
  max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Margin to separate from heading */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const PageHeading = styled.h1`
  text-align: center;
  color: #324e6a;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;
const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #287b9c; /* Primary color for header */
  color: white; /* Text color for header */
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; /* Light grey for even rows */
  }
`;

const EmptyRow = styled(Tr)`
  height: 50px; /* Set height to match filled rows */
  background-color: #f9f9f9; /* Light grey background for empty rows */
`;

const Loading = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
`;

const HealthOfficersList = () => {
  const [healthOfficers, setHealthOfficers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); 
  const jwtToken = sessionStorage.getItem('jwtToken');

  useEffect(() => {
    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      setError('No JWT token available for authentication');
      setLoading(false);
      return; 
    }

    const fetchHealthOfficers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/user/health-officer`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`,
          },
        });
        setHealthOfficers(response.data);
      } catch (error) {
        console.error('Error fetching health officers:', error);
        setError('Error fetching health officers');
      } finally {
        setLoading(false);
      }
    };

    fetchHealthOfficers();
  }, [jwtToken]);

  // Generate empty rows if healthOfficers.length < 9
  const totalRows = 9;
  const emptyRows = totalRows - healthOfficers.length;

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="health-officers-list">
      <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
      <PageHeading>Health Officers List</PageHeading>
        <TableContainer rowCount={healthOfficers.length || emptyRows}>
          {loading ? (
            <Loading>Loading...</Loading>
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Mobile No</Th>
                  <Th>Specialist</Th>
                </tr>
              </thead>
              <tbody>
                {healthOfficers.map((officer, index) => (
                  <Tr key={index}>
                    <Td>{officer.name}</Td>
                    <Td>{officer.email}</Td>
                    <Td>{officer.mobileNo}</Td>
                    <Td>{officer.specialist}</Td>
                  </Tr>
                ))}
                {/* Add empty rows */}
                {emptyRows > 0 && [...Array(emptyRows)].map((_, index) => (
                  <EmptyRow key={`empty-${index}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </EmptyRow>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </MainContent>
    </div>
  );
};

export default HealthOfficersList;
