
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Footer';
import doctorImage from '../../../Assets/docimage.png';
import Consultation from "../../../Assets/Consultation.png";
import Videomessage from "../../../Assets/Video Message.png";
import Timesheet from "../../../Assets/Timesheet.png";
import TodayPatientList from "../../../Assets/TodayPatientList.png";
import Header from "../../Hoheader";
import { BASE_URL } from '../../config/apiConfig';
import Redirect from "../../../Components/Redirect";
const Dashdoc = () => {
  const [doctorName, setDoctorName] = useState("");
  const [doctorSpecialist, setDoctorSpecialist] = useState("");
  const [doctorRole, setDoctorRole] = useState("");
  const [totalAppointmentsToday, setTotalAppointmentsToday] = useState(0);
   const [doctorInfo, setDoctorInfo] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch doctor's details from your API
    const fetchDoctorDetails = async () => {
      try {
        const key = sessionStorage.getItem('jwtToken');

        if (!key) {
            console.error('No JWT token found in sessionStorage');
            return;
        }
      const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
      headers: {
        'Auth': `Bearer ${key}`, // Use 'Authorization' for the header key
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();

    // Capitalize the first letter of the doctor's name and specialist
    const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
    const capitalizedSpecialist = data.specialist ? data.specialist.charAt(0).toUpperCase() + data.specialist.slice(1) : '';

    setDoctorInfo({
      name: capitalizedDoctorName,
      specialist: capitalizedSpecialist,
      role: data.role
    }); // Update the state with the capitalized names
  } catch (error) {
    console.error('Error fetching doctor info:', error);
    // Handle error
  }
};

    fetchDoctorDetails();
  }, []);

  const handleVideoConsultationClick = () => {
    navigate('/hovideo');
  };

  const handleAppointmentHistoryClick = () => {
    navigate('/appointment');
  };

  const handleCaseHistoryClick = () => {
    navigate('/casehistory');
  };

  const handlePreviousConsultationClick = () => {
    navigate('/preconsulting');
  };

  const handleTodayPatientListClick = () => {
    navigate('/list');
  };

  return (
    <div className="dashdoc-container">
        <Redirect />
      <Header />
      <div className="background-image"></div>
      <div className="doctor-info-box">
        <h2>Welcome Back,</h2>
        
       {doctorInfo && (
    <>
      <h3>HealthOfficer. {doctorInfo.name || 'Name not available'}</h3> {/* Fallback text */}
      <p>{doctorInfo.specialist || 'Specialist not available'}</p> {/* Fallback text */}
    </>
  )}
        {/* <p style={{ color: 'green', textTransform: 'capitalize' }}>{doctorSpecialist}</p> */}


        <p>You have assigned a total of {totalAppointmentsToday} appointments today!</p>
      </div>

      <div className="doctor-image-container">
        <img src={doctorImage} alt="Doctor" className="doctor-image" />
      </div>

      <div className="cart-container">
        <div className="cart dark-blue-bg" onClick={handlePreviousConsultationClick}>
          <div className="icon red-icon">
            <img src={Consultation} alt="Video Message Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Pre-Consultation</p>
          </div>
        </div>

        <div className="cart dark-blue-bg" onClick={handleTodayPatientListClick}>
          <div className="icon red-icon">
            <img src={TodayPatientList} alt="History Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Today’s Patient List</p>
          </div>
        </div>

        <div className="cart dark-blue-bg" onClick={handleVideoConsultationClick}>
          <div className="icon red-icon">
            <img src={Videomessage} alt="Video Message Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Video Consultation</p>
          </div>
        </div>

        <div className="cart dark-blue-bg" onClick={handleAppointmentHistoryClick}>
          <div className="icon red-icon">
            <img src={Timesheet} alt="Timesheet Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Appointment History</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashdoc;
