
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectComponent = () => {
  const history = useNavigate();

  useEffect(() => {
    const key = sessionStorage.getItem('jwtToken');
    if (!key) {
   
      history('/');
    }
  }, [history]);

  return null; 
};

export default RedirectComponent;