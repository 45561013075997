import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/hovideo':
                return "Video Consultation";
            case '/list':
                return "Today's Patient List";
            case '/appointment':
                return "Appointment History";
            case '/appointment':
                return "Today's Patient List";
            default:
                return "Video Consultation";
        }
    };

    const [activeTab, setActiveTab] = useState(getActiveTab);

    useEffect(() => {
        setActiveTab(getActiveTab);
    }, [location]);

    return (
        <div>
            <header style={styles.header1}>
                <Link
                    to="/hovideo"
                    style={activeTab === "Video Consultation" ? {...styles.tab1, ...styles.active1} : styles.tab1}
                    onClick={() => setActiveTab("Video Consultation")}
                >
                    Video Consultation
                </Link>
                <Link
                    to="/list"
                    style={activeTab === "Today's Patient List" ? {...styles.tab1, ...styles.active1} : styles.tab1}
                    onClick={() => setActiveTab("Today's Patient List")}
                >
                    Today's Patient List
                </Link>
                <Link
                    to="/appointment"
                    style={activeTab === "Appointment History" ? {...styles.tab1, ...styles.active1} : styles.tab1}
                    onClick={() => setActiveTab("Appointment History")}
                >
                    Appointment History
                </Link>
               
            </header>
        </div>
    );
}

const styles = {
    header1: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#C3C3C3',
        padding: '5px 0',
    },
    tab1: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '10px 20px',
        transition: 'background-color 0.3s ease',
        color: 'white',
        textDecoration: 'none',
    },
    active1: {
        backgroundColor: '#007bff',
        borderRadius: '5px',
    },
};

export default Header;
// import React, { useState } from 'react';
// import VideoCall from '../../../Components/Video/Videocall';        // Import VideoCall component
// import TodayPatient from '../../Healthofficer/Todaylist/Todaylist';  // Import TodayPatient component
// import Appointment from '../../Healthofficer/Appointmenthistory/Appointmenthistory';    // Import Appointment component

// function Header() {
//     // State to manage the currently active tab
//     const [activeTab, setActiveTab] = useState("Video Consultation");

//     // Function to render content based on the active tab
//     const renderContent = () => {
//         switch (activeTab) {
//             case "Video Consultation":
//                 return <VideoCall />;  // Render VideoCall component
//             case "Today's Patient List":
//                 return <TodayPatient />;  // Render TodayPatient component
//             case "Appointment History":
//                 return <Appointment />;  // Render Appointment component
//             default:
//                 return <VideoCall />;  // Default to VideoCall component
//         }
//     };

//     return (
//         <div>
//             <header style={styles.header}>
//                 <button
//                     style={activeTab === "Video Consultation" ? {...styles.tab, ...styles.active} : styles.tab}
//                     onClick={() => setActiveTab("Video Consultation")}
//                 >
//                     Video Consultation
//                 </button>
//                 <button
//                     style={activeTab === "Today's Patient List" ? {...styles.tab, ...styles.active} : styles.tab}
//                     onClick={() => setActiveTab("Today's Patient List")}
//                 >
//                     Today's Patient List
//                 </button>
//                 <button
//                     style={activeTab === "Appointment History" ? {...styles.tab, ...styles.active} : styles.tab}
//                     onClick={() => setActiveTab("Appointment History")}
//                 >
//                     Appointment History
//                 </button>
//             </header>

//             {/* Render the content based on the active tab */}
//             <div style={styles.content}>
//                 {renderContent()}
//             </div>
//         </div>
//     );
// }

// const styles = {
//     header: {
//         display: 'flex',
//         justifyContent: 'space-around',
//         alignItems: 'center',
//         backgroundColor: '#C3C3C3',
//         padding: '5px 0',
//     },
//     tab: {
//         backgroundColor: 'transparent',
//         border: 'none',
//         cursor: 'pointer',
//         fontSize: '16px',
//         padding: '10px 20px',
//         transition: 'background-color 0.3s ease',
//         color: 'white',
//         textDecoration: 'none',
//     },
//     active: {
//         backgroundColor: '#007bff',
//         borderRadius: '5px',
//     },
//     content: {
//         padding: '20px',
//         backgroundColor: '#f4f4f4',
//     }
// };

// export default Header;

