import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Legal from "./Pages/Videoconsultation";
import Casehistory from "./Pages/Casehistory";
import NotFound from "./Pages/NotFound";
import Drappoinment from "./Pages/Drappoinment";
import Dashdoc from "./Components/Dashdoc";
import Vitalsdoc from "./Components/Vitalsdoc";
import Drpatientlist from "./Components/Drpatientlist";
import Drchangepassword from "./Components/Drchangepassword";
//==============================video===============================

import Videohome from "./Components/Video/videohome";
import WebRTCClient from "./Components/Video/WebRTCClient";
import { VideoCallProvider } from './Components/Video/VideoCallContext';

// ===============================for Admin panel===================

import Adminlogin from "./Components/Adminpanel/adminlogin";
import Admindashbord from "./Components/Adminpanel/Admindashboard";
import Register from "./Components/Adminpanel/Register";
import Registerhospital from "./Components/Adminpanel/Registerhospital";
import Doctorlist from "./Components/Adminpanel/doctorlist";
import Healthofficerlist from "./Components/Adminpanel/Healthofficerlist";
import Patient from "./Components/Adminpanel/patient";

//=========================================HealthOfficer===============

import Dashboard from "./Components/Healthofficer/Hodashboard/Hodashboard";
import Preconsulting from "./Components/Healthofficer/Preconsulting/Preconsulting";
import List from "./Components/Healthofficer/Todaylist/Todaylist";
import Appointment from "./Components/Healthofficer/Appointmenthistory/Appointmenthistory";
import Patientlist from "./Components/Healthofficer/Patientlist/Patientlist";
import Consultation from "./Components/Healthofficer/Consultation/Consultation";
import Clinichistory from "./Components/Healthofficer/Clinichistory/Clinichistory";
import Updatehealth from "./Components/Healthofficer/Updatehealth/Updatehealth";
import Hochangepassword from "./Components/Healthofficer/Changepassword/Changepassword";
import Hovideo from "./Pages/Hovideo";
import Registerpatient from "./Components/Healthofficer/Registerpatient/Registerpatient";

//=============================================patient===================================

import Userdashboard from "./Components/Patient/Userdashboard/Userdashboard";
import Vitals from "./Components/Patient/Vitals/Vitals";
import Testresult from "./Components/Patient/Testresult/Testresult";
import Login from "./Components/Patient/Login/Login";
import Graph from "./Components/Patient/Graph/Graph";

//===============================================visitorepage===============================

import Homepage from "./Components/Visitorpage/Homepage";
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <VideoCallProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashdoc" element={<Dashdoc />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/dashdoc" element={<Dashdoc />} />
            <Route path="/vitalsdoc" element={<Vitalsdoc />} />
            <Route path="/drappoinment" element={<Drappoinment />} />
            <Route path="/drpatientlist" element={<Drpatientlist />} />
            <Route path="/casehistory" element={<Casehistory />} />
            <Route path="/Drchangepassword" element={<Drchangepassword />} />
            <Route path="*" element={<NotFound />} />
            {/* //===================================================adminpanel================================ */}
            <Route path="/adminlogin" element={<Adminlogin />} />
            <Route path="/admindashboard" element={<Admindashbord />} />
            <Route path="/register" element={<Register />} />
            <Route path="/registerhospital" element={<Registerhospital />} />
            <Route path="/doctorlist" element={<Doctorlist />} />
            <Route path="/healthofficerlist" element={<Healthofficerlist />} />
            <Route path="/patient" element={<Patient />} />
            {/* //=============================================HealthOfficer======================================================= */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/preconsulting" element={<Preconsulting />} />
            <Route path="/list" element={<List />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/patientlist" element={<Patientlist />} />
            <Route path="/consultation" element={<Consultation />} />
            <Route path="/clinichistory" element={<Clinichistory />} />
            <Route path="/updatehealth" element={<Updatehealth />} />
            <Route path="/hochangepassword" element={<Hochangepassword />} />
            <Route path="/hovideo" element={<Hovideo />} />
            <Route path="/registerpatient" element={<Registerpatient />} />
            {/* //==================================================patient==================================================== */}
            <Route path="/userdashboard" element={<Userdashboard />} />
            <Route path="/vitals" element={<Vitals />} />
            <Route path="/testresult" element={<Testresult />} />
            <Route path="/graph" element={<Graph />} />
          
            {/* //==================================visitorpage=================================== */}
            <Route path="/" element={<Homepage />} />
            {/* //===========================testing============================= */}
            <Route path="/webRTCClient" element={<WebRTCClient />} />
            <Route path="/videohome/:id" element={<Videohome />} />
          </Routes>
        </VideoCallProvider>
      </Router>
    </div>
  );
}

export default App;
