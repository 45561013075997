
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { BASE_URL } from '../config/apiConfig';

Modal.setAppElement('#root');

const RegisterForm = () => {
  const [hospitalName, setHospitalName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [registrationTermCondition, setRegistrationTermCondition] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errors, setErrors] = useState({});


//   const validatePassword = (password) => {
//   // Regular expression for a password that must contain:
//   // At least one uppercase letter, one lowercase letter, one number, and one special character
//   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
//   return passwordRegex.test(password);
// };

const validatePassword = (password) => {
  const passwordRegex = /^.{8,}$/;
  return passwordRegex.test(password);
};


  const handleHospitalNameChange = (event) => {
    setHospitalName(event.target.value);
    setErrors({ ...errors, hospitalName: '' });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors({ ...errors, email: '' });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setErrors({ ...errors, password: '' });
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
    setErrors({ ...errors, mobile: '' });
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setErrors({ ...errors, address: '' });
  };

  const handleTermsChange = () => {
    setRegistrationTermCondition(!registrationTermCondition);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

 

  const validateMobileNumber = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

  const validateHospitalName = (hospitalName) => {
    return hospitalName.trim() !== ''; 
  };

  const validateForm = () => {
    let formErrors = {};

    if (!validateHospitalName(hospitalName)) {
      formErrors.hospitalName = 'Please enter a hospital name.';
    }

    if (!validateEmail(email)) {
      formErrors.email = 'Please enter a valid email address.';
    }

   if (!validatePassword(password)) {
  errors.password = 'Password must be at least 8 characters long.';
}


    if (!validateMobileNumber(mobile)) {
      formErrors.mobile = 'Please enter a valid 10-digit mobile number.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!registrationTermCondition) {
      alert('Please accept the terms and conditions.');
      return;
    }

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      userName: hospitalName,
      email,
      password,
      mobileNo: mobile,
      registrationTermCondition: true,
    };

    try {
      const response = await fetch(`${BASE_URL}/user/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const contentType = response.headers.get('Content-Type');
      let result;

      if (contentType && contentType.includes('application/json')) {
        result = await response.json();
      } else {
        const text = await response.text();
        result = { message: text };
      }

      if (!response.ok) {
        if (result.message.includes('mobile number')) {
          setModalMessage('The mobile number already exists.');
        } else if (result.message.includes('email')) {
          setModalMessage('The email address already exists.');
        } else {
          setModalMessage(result.message || 'Registration failed');
        }
        setIsModalOpen(true);
        return;
      }

      setHospitalName('');
      setEmail('');
      setPassword('');
      setMobile('');
      setAddress('');
      setRegistrationTermCondition(false);

      setModalMessage(result.message || 'Registration successful');
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setModalMessage('Registration failed');
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={styles.container}>

      <h2 style={styles.heading}>Hospital Registration</h2>
      <div className="register-form-container" style={styles.formContainer}>
        <form onSubmit={handleSubmit} className="register-form">
          <div className="form-group">
            <input
              type="text"
              id="hospitalName"
              value={hospitalName}
              onChange={handleHospitalNameChange}
              placeholder="Hospital Name"
              style={styles.input}
              required
            />
            {errors.hospitalName && <p style={styles.errorText}>{errors.hospitalName}</p>}
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              style={styles.input}
              required
            />
            {errors.email && <p style={styles.errorText}>{errors.email}</p>}
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              style={styles.input}
              required
            />
            {errors.password && <p style={styles.errorText}>{errors.password}</p>}
          </div>
          <div className="form-group">
            <input
              type="text"
              id="mobile"
              value={mobile}
              onChange={handleMobileChange}
              placeholder="Mobile Number"
              style={styles.input}
              inputMode="numeric"
              pattern="[0-9]*"
              required
            />
            {errors.mobile && <p style={styles.errorText}>{errors.mobile}</p>}
          </div>
          <div className="form-group">
            <input
              type="text"
              id="address"
              value={address}
              onChange={handleAddressChange}
              placeholder="Address"
              style={styles.input}
            />
            {errors.address && <p style={styles.errorText}>{errors.address}</p>}
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={registrationTermCondition}
                onChange={handleTermsChange}
                style={{ marginRight: '10px' }}
                required
              />
              I accept the terms and conditions
            </label>
          </div>
          <div className="form-group">
            <button type="submit" style={styles.button}>Submit</button>
          </div>
        </form>
        <p style={styles.linkText}>
          Already have an account? <Link to="/login" style={styles.link}>Login</Link>
        </p>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Registration Status Modal"
      >
        <h2 style={styles.modalHeading}>{modalMessage}</h2>
        <button onClick={closeModal} style={styles.modalButton}>Close</button>
      </Modal>
    </div>
  );
};

// Styles for the component
const styles = {
  container: {
    background: 'linear-gradient(#7CB2D0, #D0E0E5)',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    marginTop: '20px',
    color: '#333',
    fontSize: '29px',
    textShadow: '2px 2px 4px rgba(255, 0, 0, 0.5)',
    marginBottom: '20px',
  },
  formContainer: {
    width: '90%',
    maxWidth: '400px',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'none',
    border: '2px solid #007bff',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    background: 'rgba(255, 255, 255, 0.3)',
    fontSize: '16px',
    border: 'none',
    marginBottom: '10px',
  
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  linkText: {
    textAlign: 'center',
    marginTop: '20px',
    color: '#333',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: '-10px',
    marginBottom: '10px',
  },
  modalHeading: {
    color: '#333',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  modalButton: {
    display: 'block',
    margin: '0 auto',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

// Modal styles
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    border: '2px solid #007bff',
  },
};

export default RegisterForm;
