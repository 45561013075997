

import React, { useState, useEffect } from 'react';
import { BASE_URL } from './config/apiConfig';
import Redirect from "../Components/Redirect";
const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    document.body.style.background = 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)';
    return () => {
      document.body.style.background = '';
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    try {
      const jwtToken = sessionStorage.getItem('jwtToken');
      const response = await fetch(`${BASE_URL}/registerDocHs/change-password`, {
        method: 'POST',
        headers: {
          'Auth': `Bearer ${jwtToken}`, // Replace with the actual JWT token
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
          confirmPassword,
        }),
      });

      if (response.ok) {
        setSuccess('Password changed successfully');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'An error occurred');
      }
    } catch (error) {
      setError('An error occurred: ' + error.message);
    }
  };

  const styles = {
    container: {
      width: '1000px',
      height: '485px',
      border: '1px solid #ccc',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#E5EFF3',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '60px',
      marginBottom: '40px'
    },
    form: {
      border: '2px solid #0F60B3',
      padding: '20px',
      borderRadius: '10px',
      width: '400px',
      height: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      textAlign: 'left',
      color: '#324E6A',
      fontSize: '50px',
      textShadow: '0px 0px 32.1px #BB1111',
      marginBottom: '20px',
      marginLeft: '20px',
    },
    formGroup: {
      marginBottom: '15px',
      width: '100%',
    },
    input: {
      width: '100%',
      padding: '10px',
      border: '1px solid #0F60B3',
      borderRadius: '5px',
    },
    button: {
      width: '50%',
      maxWidth: '200px',
      padding: '10px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#0F60B3',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    message: {
      color: 'red',
      marginTop: '10px',
    },
    successMessage: {
      color: 'green',
      marginTop: '10px',
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Redirect />
      <div style={styles.container}>
        <h2 style={styles.title}>Change Password</h2>
        <form style={styles.form} onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <input
              type="password"
              placeholder="Old Password"
              style={styles.input}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <input
              type="password"
              placeholder="New Password"
              style={styles.input}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <input
              type="password"
              placeholder="Confirm Password"
              style={styles.input}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            style={styles.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
          >
            Submit
          </button>
          {error && <div style={styles.message}>{error}</div>}
          {success && <div style={styles.successMessage}>{success}</div>}
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
