
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Homecontact.css'; 
import Footer from './Visitorfooter.js'; 
import homeBackground from "../../Assets/home.png";
import logo from "../../Assets/O2I_LOGO.png"; // Import your image here
import { FaHospital, FaUser, FaUserMd, FaStethoscope, FaFileAlt, FaHeartbeat } from 'react-icons/fa';

function Homepage() {
    const registeredHospitals = 1;
    const registeredPatients = 10;
    const registeredDoctors = 1;

    useEffect(() => {
        const cards = document.querySelectorAll(".card");
        cards.forEach((card, index) => {
            setTimeout(() => {
                card.style.transform = "translateY(0)";
                card.style.opacity = "1";
            }, index * 100);
        });
    }, []);

    useEffect(() => {
        const processCards = document.querySelectorAll(".process-card");
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.style.transform = "translateX(0)";
                    entry.target.style.opacity = "1";
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1,
        });

        processCards.forEach((card) => {
            observer.observe(card);
        });

        return () => {
            processCards.forEach((card) => {
                observer.unobserve(card);
            });
        };
    }, []);

    return (
        <div className="homepage">
            <div className="header">
            <div className="header-text">
                    <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
                </div>
                <div className="buttons-container">
                    <Link to="/registerhospital" className="login-btn">Login/Register</Link>
                    
                </div>
            </div>
            <div className="background-container">
                <div className="text-overlay">
                    <h1>Oxygen 2 Innovation Telemedicine Platform</h1>
                </div>
                <img src={homeBackground} alt="Home Background" className="home-background"/>
            </div>

            <div className="card-container">
                <div className="card">
                    <div className="icon-container">
                        <FaHospital className="card-icon" />
                    </div>
                    <div className="card-content">
                        <h2>Registered Hospitals</h2>
                        <p>{registeredHospitals}</p>
                    </div>
                </div>
                <div className="card">
                    <div className="icon-container">
                        <FaUser className="card-icon" />
                    </div>
                    <div className="card-content">
                        <h2>Registered Patients</h2>
                        <p>{registeredPatients}</p>
                    </div>
                </div>
                <div className="card">
                    <div className="icon-container">
                        <FaUserMd className="card-icon" />
                    </div>
                    <div className="card-content">
                        <h2>Registered Doctors</h2>
                        <p>{registeredDoctors}</p>
                    </div>
                </div>
            </div>

            <div className="process-heading">
                <h2>Let's See How We Process</h2>
            </div>
            <div className="process-container">
    <div className="process-card">
        <div className="number-container">1</div>
        <div className="icon-container">
            <FaStethoscope className="process-icon" />
        </div>
        <h2>Checkup</h2>
        <p>Perform health checkups on registered patients.</p>
    </div>
    <div className="process-card">
        <div className="number-container">2</div>
        <div className="icon-container">
            <FaFileAlt className="process-icon" />
        </div>
        <h2>Get Results</h2>
        <p>Retrieve and review patient health results.</p>
    </div>
    <div className="process-card">
        <div className="number-container">3</div>
        <div className="icon-container">
            <FaHeartbeat className="process-icon" />
        </div>
        <h2>Ongoing Care</h2>
        <p>Provide continuous care and monitoring.</p>
    </div>
    <div className="process-card">
        <div className="number-container">4</div>
        <div className="icon-container">
            <FaHeartbeat className="process-icon" />
        </div>
        <h2>Follow-up</h2>
        <p>Provide post-care follow-up for patients.</p>
    </div>
</div>


            {/* <div className="process-container">
                <div className="process-card">
                    <div className="number-container">1</div>
                    <div className="icon-container">
                        <FaUser className="process-icon" />
                    </div>
                    <h2>Patient Registration</h2>
                    <p>Register new patients into the system.</p>
                </div>
                <div className="process-card">
                    <div className="number-container">2</div>
                    <div className="icon-container">
                        <FaStethoscope className="process-icon" />
                    </div>
                    <h2>Checkup</h2>
                    <p>Perform health checkups on registered patients.</p>
                </div>
                <div className="process-card">
                    <div className="number-container">3</div>
                    <div className="icon-container">
                        <FaFileAlt className="process-icon" />
                    </div>
                    <h2>Get Results</h2>
                    <p>Retrieve and review patient health results.</p>
                </div>
                <div className="process-card">
                    <div className="number-container">4</div>
                    <div className="icon-container">
                        <FaHeartbeat className="process-icon" />
                    </div>
                    <h2>Ongoing Care</h2>
                    <p>Provide continuous care and monitoring.</p>
                </div>
            </div> */}

            <div className="content-container">
                <div className="content-left">
                    <h1>Welcome to Our Telemedicine Platform</h1>
                    <p>Telemedicine connects patients with doctors virtually through health officers. Health officers gather patient details, including vitals and medical history, and assist in virtual consultations. Doctors provide expert medical advice, diagnose conditions, and prescribe treatments, ensuring seamless, remote healthcare access without the need for in-person visits.</p>
                </div>
                <div className="content-right">
                    <div className="query-section">
                        <div className="query-box">
                            <h3>Drop Us a Message For Any Query</h3>
                            <form>
                                <input type="text" placeholder="Name" />
                                <input type="email" placeholder="Email" />
                                <input type="tel" placeholder="Mobile Number" />
                                <textarea placeholder="Your message"></textarea>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Homepage;


















