// VideoCallContext.js
import React, { createContext, useState, useContext } from 'react';
import PersistentVideoCall from './PersistentVideoCall';
const VideoCallContext = createContext();

export const VideoCallProvider = ({ children }) => {
  const [roomID, setRoomID] = useState(null);

  return (
    <VideoCallContext.Provider value={{ roomID, setRoomID }}>
      {children}
      {roomID && <PersistentVideoCall roomID={roomID} />}
    </VideoCallContext.Provider>
  );
};

export const useVideoCall = () => useContext(VideoCallContext);
