// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { VideoCallProvider } from './Components/Video/VideoCallContext';

ReactDOM.render(
  <React.StrictMode>
    <VideoCallProvider>
      <App />
    </VideoCallProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
