



//====================================================================correctcode===========================================
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/legal':
                return "Video Consultation";
            case '/drpatientlist':
                return "Today's Patient List";
            case '/drappoinment':
                return "Appointment History";
            case '/drpatientlist':
                return "Patient List";
            default:
                return "Video Consultation";
        }
    };

    const [activeTab, setActiveTab] = useState(getActiveTab);

    useEffect(() => {
        setActiveTab(getActiveTab);
    }, [location]);

    return (
        <div>
            <header style={styles.header}>
                
                <Link
                    to="/legal"
                    style={activeTab === "Video Consultation" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Video Consultation")}
                >
                    Video Consultation
                </Link>

                <Link
                    to="/drpatientlist"
                    style={activeTab === "Today's Patient List" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Today's Patient List")}
                >
                    Today's Patient List
                </Link>

                <Link
                    to="/drappoinment"
                    style={activeTab === "Appointment History" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Appointment History")}
                >
                    Appointment History
                </Link>
               
            </header>
        </div>
    );
}

const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#C3C3C3',
        padding: '5px 0',
    },
    tab: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '10px 20px',
        transition: 'background-color 0.3s ease',
        color: 'white',
        textDecoration: 'none',
    },
    active: {
        backgroundColor: '#007bff',
        borderRadius: '5px',
    },
};

export default Header;
