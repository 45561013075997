
import backgroundImage from "../Assets/backgroud.png"; // Import the image
import React from "react";
import styled from "styled-components";
import Videoheader from "../Components/Video/Videoheader";
import Drpatinfo from "../Components/Video/Drpatinfo";
import Videocall from "../Components/Video/Dvideopcall";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Redirect from "../Components/Redirect"; // Import Redirect.js
const AppWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;

const ContentWrapper = styled.div`
  padding: 2px;
`;

const HeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

function Home1() {
  return (
    <AppWrapper>
  
      <Background />
      <ContentWrapper>
        <HeaderWrapper>
        <Redirect />
          <Header />
        </HeaderWrapper>
        <Videoheader />
        <Drpatinfo />
        <Videocall />
        <Footer />
      </ContentWrapper>
    </AppWrapper>
  );
}

export default Home1;
