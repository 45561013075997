
import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaHome, FaUserMd, FaUserNurse, FaUserInjured, FaSignOutAlt } from 'react-icons/fa';

const SidebarContainer = styled.div`
  width: 250px;
  height: 100vh;
  background-color: #7CB2D0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarHeading = styled.h1`
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 24px;
`;

const SidebarItem = styled(Link)`
  width: 100%;
  padding: 15px 20px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #D0E0E5;
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const Sidebar = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const logout = () => {
    sessionStorage.removeItem('jwtToken'); // Ensure you are using the correct key
    navigate('/'); // Redirect to login page
  };

  return (
    <SidebarContainer>
      <SidebarHeading>Hospital Name</SidebarHeading>
      <SidebarItem to="/admindashboard">
        <IconWrapper><FaHome /></IconWrapper>
        Home
      </SidebarItem>
      <SidebarItem to="/doctorlist">
        <IconWrapper><FaUserMd /></IconWrapper>
        Doctor
      </SidebarItem>
      <SidebarItem to="/healthofficerlist">
        <IconWrapper><FaUserNurse /></IconWrapper>
        Health Officer
      </SidebarItem>
      <SidebarItem to="/patient">
        <IconWrapper><FaUserInjured /></IconWrapper>
        Patient
      </SidebarItem>
      <SidebarItem to="/" onClick={logout}>
        <IconWrapper><FaSignOutAlt /></IconWrapper>
        Logout
      </SidebarItem>
    </SidebarContainer>
  );
};

export default Sidebar;
