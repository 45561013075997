
// // // import React, { useState, useEffect } from 'react';
// // // import axios from 'axios';
// // // import Sidebar from './Sidebar';
// // // import styled from 'styled-components';
// // // import Header from './Header'; 
// // // import { BASE_URL } from '../config/apiConfig';
// // // import Redirect from "../../Components/Redirect";

// // // const SidebarStyled = styled.div`
// // //   position: fixed; 
// // //   top: 0;
// // //   left: 0;
// // //   height: 100vh; 
// // //   width: 250px; 
// // //   background-color: #f4f4f4;
// // //   overflow-y: auto; 
// // //   z-index: 1000; 
// // // `;

// // // const MainContent = styled.div`
// // //   margin-left: 250px; 
// // //   padding: 20px;
// // //   font-family: Arial, sans-serif;
// // // `;

// // // const Table = styled.table`
// // //   width: 100%;
// // //   border-collapse: collapse;
// // // `;

// // // const Th = styled.th`
// // //   padding: 10px;
// // //   border: 1px solid #ddd;
// // //   text-align: left;
// // //   background-color: #f4f4f4;
// // // `;

// // // const Td = styled.td`
// // //   padding: 10px;
// // //   border: 1px solid #ddd;
// // //   text-align: left;
// // // `;

// // // const Tr = styled.tr`
// // //   &:nth-child(even) {
// // //     background-color: #f9f9f9;
// // //   }
// // // `;

// // // const PatientsList = () => {
// // //   const [patients, setPatients] = useState([]);
// // //   const [loading, setLoading] = useState(true);
// // //   const [error, setError] = useState(null);
// // //   const jwtToken = sessionStorage.getItem('jwtToken');

// // //   useEffect(() => {
// // //     if (!jwtToken) {
// // //       setError('No JWT token available for authentication');
// // //       setLoading(false);
// // //       return;
// // //     }

// // //     const fetchPatients = async () => {
// // //       try {
// // //         const response = await axios.get(`${BASE_URL}/user/patients`, {
// // //           headers: {
// // //             'Auth': `Bearer ${jwtToken}`
// // //           }
// // //         });
// // //         setPatients(response.data);
// // //       } catch (error) {
// // //         setError('Error fetching patients');
// // //       } finally {
// // //         setLoading(false);
// // //       }
// // //     };

// // //     fetchPatients();
// // //   }, [jwtToken]);

// // //   if (loading) {
// // //     return <p>Loading...</p>;
// // //   }

// // //   if (error) {
// // //     return <p>{error}</p>;
// // //   }

// // //   return (
// // //     <div className="patients-list">
// // //         <Redirect />
// // //       <Header />
// // //       <SidebarStyled>
// // //         <Sidebar />
// // //       </SidebarStyled>
// // //       <MainContent>
// // //         <h1>Patients List</h1>
// // //         <Table>
// // //           <thead>
// // //             <tr>
// // //               <Th>Patient ID</Th>
// // //               <Th>Name</Th>
// // //               <Th>Doctor</Th>
// // //               <Th>Age</Th>
// // //               <Th>Gender</Th>
// // //               <Th>Status</Th>
// // //               <Th>Health Officer</Th>
// // //               <Th>Aadhaar Card Number</Th>
// // //               <Th>Appointment Time</Th>
// // //             </tr>
// // //           </thead>
// // //           <tbody>
// // //             {patients.map((patient, index) => (
// // //               <Tr key={index}>
// // //                 <Td>{patient.patientId}</Td>
// // //                 <Td>{patient.patientName}</Td>
// // //                 <Td>{patient.doctor}</Td>
// // //                 <Td>{patient.age}</Td>
// // //                 <Td>{patient.gender}</Td>
// // //                 <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
// // //                 <Td>{patient.healthOfficer}</Td>
// // //                 <Td>{patient.aadhaarCardNumber}</Td>
// // //                 <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
// // //               </Tr>
// // //             ))}
// // //           </tbody>
// // //         </Table>
// // //       </MainContent>
// // //     </div>
// // //   );
// // // };

// // // export default PatientsList;
// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import Sidebar from './Sidebar';
// // import styled from 'styled-components';
// // import Header from './Header'; 
// // import { BASE_URL } from '../config/apiConfig';
// // import Redirect from "../../Components/Redirect";

// // const SidebarStyled = styled.div`
// //   position: fixed; 
// //   top: 0;
// //   left: 0;
// //   height: 100vh; 
// //   width: 250px; 
// //   background-color: #f4f4f4;
// //   overflow-y: auto; 
// //   z-index: 1000; 
// // `;

// // const MainContent = styled.div`
// //   margin-left: 250px; 
// //   padding: 20px;
// //   font-family: Arial, sans-serif;
// //   background-color: #f8f9fa;
// //   min-height: 100vh;
// // `;

// // const Table = styled.table`
// //   width: 100%;
// //   border-collapse: separate;
// //   border-spacing: 0;
// //   border-radius: 10px;
// //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// //   background-color: #fff;
// //   overflow: hidden;
// // `;

// // const Th = styled.th`
// //   padding: 12px 15px;
// //   text-align: left;
// //   background-color: #007bff;
// //   color: #fff;
// //   font-weight: bold;
// //   text-transform: uppercase;
// //   border-bottom: 1px solid #ddd;
// // `;

// // const Td = styled.td`
// //   padding: 12px 15px;
// //   border-bottom: 1px solid #ddd;
// //   text-align: left;
// //   color: #333;
// //   font-size: 14px;
// //   background-color: #f9f9f9;
// // `;

// // const Tr = styled.tr`
// //   &:nth-child(even) {
// //     background-color: #f1f1f1;
// //   }
  
// //   &:hover {
// //     background-color: #e0f7fa;
// //   }
// // `;

// // const PatientsList = () => {
// //   const [patients, setPatients] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);
// //   const jwtToken = sessionStorage.getItem('jwtToken');

// //   useEffect(() => {
// //     if (!jwtToken) {
// //       setError('No JWT token available for authentication');
// //       setLoading(false);
// //       return;
// //     }

// //     const fetchPatients = async () => {
// //       try {
// //         const response = await axios.get(`${BASE_URL}/user/patients`, {
// //           headers: {
// //             'Auth': `Bearer ${jwtToken}`
// //           }
// //         });
// //         setPatients(response.data);
// //       } catch (error) {
// //         setError('Error fetching patients');
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchPatients();
// //   }, [jwtToken]);

// //   if (loading) {
// //     return <p>Loading...</p>;
// //   }

// //   if (error) {
// //     return <p>{error}</p>;
// //   }

// //   return (
// //     <div className="patients-list">
// //         <Redirect />
// //       <Header />
// //       <SidebarStyled>
// //         <Sidebar />
// //       </SidebarStyled>
// //       <MainContent>
// //         <h1>Patients List</h1>
// //         <Table>
// //           <thead>
// //             <tr>
// //               <Th>Patient ID</Th>
// //               <Th>Name</Th>
// //               <Th>Doctor</Th>
// //               <Th>Age</Th>
// //               <Th>Gender</Th>
// //               <Th>Status</Th>
// //               <Th>Health Officer</Th>
// //               <Th>Aadhaar Card Number</Th>
// //               <Th>Appointment Time</Th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {patients.map((patient, index) => (
// //               <Tr key={index}>
// //                 <Td>{patient.patientId}</Td>
// //                 <Td>{patient.patientName}</Td>
// //                 <Td>{patient.doctor}</Td>
// //                 <Td>{patient.age}</Td>
// //                 <Td>{patient.gender}</Td>
// //                 <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
// //                 <Td>{patient.healthOfficer}</Td>
// //                 <Td>{patient.aadhaarCardNumber}</Td>
// //                 <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
// //               </Tr>
// //             ))}
// //           </tbody>
// //         </Table>
// //       </MainContent>
// //     </div>
// //   );
// // };

// // export default PatientsList;
// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import Sidebar from './Sidebar';
// // import styled from 'styled-components';
// // import Header from './Header'; 
// // import { BASE_URL } from '../config/apiConfig';
// // import Redirect from "../../Components/Redirect";

// // // Styled Components

// // const SidebarStyled = styled.div`
// //   position: fixed; 
// //   top: 0;
// //   left: 0;
// //   height: 100vh; 
// //   width: 250px; 
// //   background-color: #f4f4f4;
// //   overflow-y: auto; 
// //   z-index: 1000; 
// // `;

// // const MainContent = styled.div`
// //   margin-left: 250px; 
// //   padding: 20px;
// //   font-family: Arial, sans-serif;
// //   background-color: #f8f9fa;
// //   min-height: 100vh;
// // `;

// // const Table = styled.table`
// //   width: 100%;
// //   border-collapse: separate;
// //   border-spacing: 0;
// //   border-radius: 10px;
// //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// //   background-color: #fff;
// //   overflow: hidden;
// // `;

// // const Th = styled.th`
// //   padding: 12px 15px;
// //   text-align: left;
// //   background-color: #007bff;
// //   color: #fff;
// //   font-weight: bold;
// //   text-transform: uppercase;
// //   border-bottom: 1px solid #ddd;
// // `;

// // const Td = styled.td`
// //   padding: 12px 15px;
// //   border-bottom: 1px solid #ddd;
// //   text-align: left;
// //   color: #333;
// //   font-size: 14px;
// //   background-color: #f9f9f9;
// // `;

// // const Tr = styled.tr`
// //   &:nth-child(even) {
// //     background-color: #f1f1f1;
// //   }
  
// //   &:hover {
// //     background-color: #e0f7fa;
// //   }
// // `;

// // const PageHeading = styled.h1`
// //   text-align: center;
// //   color: #324e6a;
// //   font-size: 36px;
// //   font-weight: bold;
// //   margin-bottom: 30px;
// // `;

// // // PatientsList Component

// // const PatientsList = () => {
// //   const [patients, setPatients] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);
// //   const jwtToken = sessionStorage.getItem('jwtToken');

// //   useEffect(() => {
// //     if (!jwtToken) {
// //       setError('No JWT token available for authentication');
// //       setLoading(false);
// //       return;
// //     }

// //     const fetchPatients = async () => {
// //       try {
// //         const response = await axios.get(`${BASE_URL}/user/patients`, {
// //           headers: {
// //             'Auth': `Bearer ${jwtToken}`
// //           }
// //         });
// //         setPatients(response.data);
// //       } catch (error) {
// //         setError('Error fetching patients');
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchPatients();
// //   }, [jwtToken]);

// //   if (loading) {
// //     return <p>Loading...</p>;
// //   }

// //   if (error) {
// //     return <p>{error}</p>;
// //   }

// //   return (
// //     <div className="patients-list">
// //       <Redirect />
// //       <Header />
// //       <SidebarStyled>
// //         <Sidebar />
// //       </SidebarStyled>
// //       <MainContent>
// //         <PageHeading>Patients List</PageHeading>
// //         <Table>
// //           <thead>
// //             <tr>
// //               <Th>Patient ID</Th>
// //               <Th>Name</Th>
// //               <Th>Doctor</Th>
// //               <Th>Age</Th>
// //               <Th>Gender</Th>
// //               <Th>Status</Th>
// //               <Th>Health Officer</Th>
// //               <Th>Aadhaar Card Number</Th>
// //               <Th>Appointment Time</Th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {patients.map((patient, index) => (
// //               <Tr key={index}>
// //                 <Td>{patient.patientId}</Td>
// //                 <Td>{patient.patientName}</Td>
// //                 <Td>{patient.doctor}</Td>
// //                 <Td>{patient.age}</Td>
// //                 <Td>{patient.gender}</Td>
// //                 <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
// //                 <Td>{patient.healthOfficer}</Td>
// //                 <Td>{patient.aadhaarCardNumber}</Td>
// //                 <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
// //               </Tr>
// //             ))}
// //           </tbody>
// //         </Table>
// //       </MainContent>
// //     </div>
// //   );
// // };

// // export default PatientsList;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from './Sidebar';
// import styled from 'styled-components';
// import Header from './Header'; 
// import { BASE_URL } from '../config/apiConfig';
// import Redirect from "../../Components/Redirect";

// // Styled Components

// const SidebarStyled = styled.div`
//   position: fixed; 
//   top: 0;
//   left: 0;
//   height: 100vh; 
//   width: 250px; 
//   background-color: #f4f4f4;
//   overflow-y: auto; 
//   z-index: 1000; 
// `;

// const MainContent = styled.div`
//   margin-left: 250px; 
//   padding: 20px;
//   font-family: Arial, sans-serif;
//   background-color: #f8f9fa;
//   min-height: 100vh;
// `;

// const TableContainer = styled.div`
//   max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
//   overflow-y: auto; /* Enable vertical scrolling */
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   margin-top: 20px; /* Margin to separate from heading */
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: separate;
//   border-spacing: 0;
//   background-color: #fff;
// `;

// const Th = styled.th`
//   padding: 12px 15px;
//   text-align: left;
//   background-color: #287b9c;
//   color: #fff;
//   font-weight: bold;
//   text-transform: uppercase;
//   border-bottom: 1px solid #ddd;
// `;

// const Td = styled.td`
//   padding: 12px 15px;
//   border-bottom: 1px solid #ddd;
//   text-align: left;
//   color: #333;
//   font-size: 14px;
//   background-color: #f9f9f9;
// `;

// const Tr = styled.tr`
//   &:nth-child(even) {
//     background-color: #f1f1f1;
//   }
  
//   &:hover {
//     background-color: #e0f7fa;
//   }
// `;

// const PageHeading = styled.h1`
//   text-align: center;
//   color: #324e6a;
//   font-size: 36px;
//   font-weight: bold;
//   margin-bottom: 30px;
// `;

// // PatientsList Component

// const PatientsList = () => {
//   const [patients, setPatients] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const jwtToken = sessionStorage.getItem('jwtToken');

//   useEffect(() => {
//     if (!jwtToken) {
//       setError('No JWT token available for authentication');
//       setLoading(false);
//       return;
//     }

//     const fetchPatients = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/user/patients`, {
//           headers: {
//             'Auth': `Bearer ${jwtToken}`
//           }
//         });
//         setPatients(response.data);
//       } catch (error) {
//         setError('Error fetching patients');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPatients();
//   }, [jwtToken]);

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <div className="patients-list">
//       <Redirect />
//       <Header />
//       <SidebarStyled>
//         <Sidebar />
//       </SidebarStyled>
//       <MainContent>
//         <PageHeading>Patients List</PageHeading>
//         <TableContainer rowCount={patients.length}> {/* Pass rowCount to TableContainer */}
//           <Table>
//             <thead>
//               <tr>
//                 <Th>Patient ID</Th>
//                 <Th>Name</Th>
//                 <Th>Doctor</Th>
//                 <Th>Age</Th>
//                 <Th>Gender</Th>
//                 <Th>Status</Th>
//                 <Th>Health Officer</Th>
//                 <Th>Aadhaar Card Number</Th>
//                 <Th>Appointment Time</Th>
//               </tr>
//             </thead>
//             <tbody>
//               {patients.map((patient, index) => (
//                 <Tr key={index}>
//                   <Td>{patient.patientId}</Td>
//                   <Td>{patient.patientName}</Td>
//                   <Td>{patient.doctor}</Td>
//                   <Td>{patient.age}</Td>
//                   <Td>{patient.gender}</Td>
//                   <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
//                   <Td>{patient.healthOfficer}</Td>
//                   <Td>{patient.aadhaarCardNumber}</Td>
//                   <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
//                 </Tr>
//               ))}
//             </tbody>
//           </Table>
//         </TableContainer>
//       </MainContent>
//     </div>
//   );
// };

// export default PatientsList;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header'; 
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect";

// Styled Components

const SidebarStyled = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh; 
  width: 250px; 
  background-color: #f4f4f4;
  overflow-y: auto; 
  z-index: 1000; 
`;

const MainContent = styled.div`
  margin-left: 250px; 
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
`;

const TableContainer = styled.div`
  max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Margin to separate from heading */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
`;

const Th = styled.th`
  padding: 12px 15px;
  text-align: left;
  background-color: #287b9c;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  color: #333;
  font-size: 14px;
  background-color: #f9f9f9;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
  
  &:hover {
    background-color: #e0f7fa;
  }
`;

const PageHeading = styled.h1`
  text-align: center;
  color: #324e6a;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 20px;
  color: #007bff;
  margin-top: 20px;
`;

// PatientsList Component

const PatientsList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const jwtToken = sessionStorage.getItem('jwtToken');

  useEffect(() => {
    if (!jwtToken) {
      setError('No JWT token available for authentication');
      setLoading(false);
      return;
    }

    const fetchPatients = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/patients`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setPatients(response.data);
      } catch (error) {
        setError('Error fetching patients');
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, [jwtToken]);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="patients-list">
      <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <PageHeading>Patients List</PageHeading>
        <TableContainer rowCount={patients.length}> {/* Pass rowCount to TableContainer */}
          {loading ? ( // Display loading message only in the table area
            <LoadingMessage>Loading...</LoadingMessage>
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Patient ID</Th>
                  <Th>Name</Th>
                  <Th>Doctor</Th>
                  <Th>Age</Th>
                  <Th>Gender</Th>
                  <Th>Status</Th>
                  <Th>Health Officer</Th>
                  <Th>Aadhaar Card Number</Th>
                  <Th>Appointment Time</Th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient, index) => (
                  <Tr key={index}>
                    <Td>{patient.patientId}</Td>
                    <Td>{patient.patientName}</Td>
                    <Td>{patient.doctor}</Td>
                    <Td>{patient.age}</Td>
                    <Td>{patient.gender}</Td>
                    <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
                    <Td>{patient.healthOfficer}</Td>
                    <Td>{patient.aadhaarCardNumber}</Td>
                    <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </MainContent>
    </div>
  );
};

export default PatientsList;
