
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BASE_URL } from '../config/apiConfig';
// import Redirect from "../../Components/Redirect";

// const PatientList = () => {
//   const [patients, setPatients] = useState([]);
//   const [loading, setLoading] = useState(true); // Add loading state
//   const [error, setError] = useState(null); // Add error state
//   const jwtToken = sessionStorage.getItem('jwtToken');

//   useEffect(() => {
//     if (!jwtToken) {
//       setError('No JWT token available for authentication');
//       setLoading(false);
//       return;
//     }

//     const fetchPatients = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/user/patients`, {
//           headers: {
//             'Auth': `Bearer ${jwtToken}`
//           }
//         });
//         setPatients(response.data);
//       } catch (error) {
//         setError('Error fetching patients');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPatients();
//   }, [jwtToken]); // Depend on jwtToken

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <div className="patient-list">
//         <Redirect />
//       <style>
//         {`
//           .patient-list {
//             padding: 20px;
//             max-width: 1000px;
//             margin: auto;
//             font-family: Arial, sans-serif;
//           }

//           h1 {
//             text-align: center;
//             margin-bottom: 20px;
//           }

//           table {
//             width: 100%;
//             border-collapse: collapse;
//           }

//           th, td {
//             padding: 10px;
//             border: 1px solid #ddd;
//             text-align: left;
//           }

//           thead {
//             background-color: #f4f4f4;
//           }

//           tr:nth-child(even) {
//             background-color: #f9f9f9;
//           }
//         `}
//       </style>
//       <table>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Name</th>
//             <th>Age</th>
//             <th>Gender</th>
//             <th>Condition</th>
//             <th>Health Officer</th>
//             <th>Doctor</th>
//             <th>Appointment Time</th>
//             <th>Aadhaar Card Number</th>
//           </tr>
//         </thead>
//         <tbody>
//           {patients.map(patient => (
//             <tr key={patient.patientId}>
//               <td>{patient.patientId}</td>
//               <td>{patient.patientName}</td>
//               <td>{patient.age}</td>
//               <td>{patient.gender}</td>
//               <td>{patient.status ? 'Sick' : 'Healthy'}</td>
//               <td>{patient.healthOfficer}</td>
//               <td>{patient.doctor}</td>
//               <td>{new Date(patient.appointmentTime).toLocaleString()}</td>
//               <td>{patient.aadhaarCardNumber}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default PatientList;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect";

const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const jwtToken = sessionStorage.getItem('jwtToken');

  useEffect(() => {
    if (!jwtToken) {
      setError('No JWT token available for authentication');
      setLoading(false);
      return;
    }

    const fetchPatients = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/patients`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setPatients(response.data);
      } catch (error) {
        setError('Error fetching patients');
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, [jwtToken]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="patient-list">
      <Redirect />
      <style>
        {`
          .patient-list {
            padding: 20px;
            max-width: 1000px;
            margin: auto;
            font-family: Arial, sans-serif;
          }

          h1 {
            text-align: center;
            margin-bottom: 20px;
          }

          table {
            width: 100%;
            border-collapse: collapse;
          }

          th, td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: left;
          }

          thead {
            background-color: #f4f4f4;
          }

          tr:nth-child(even) {
            background-color: #f9f9f9;
          }

          .table-container {
            max-height: 300px; /* Adjusted height for 10 rows */
            overflow-y: auto;
          }
        `}
      </style>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Health Officer</th>
              <th>Doctor</th>
              <th>Appointment Time</th>
              <th>Aadhaar Card Number</th>
            </tr>
          </thead>
          <tbody>
            {patients.slice(0, 10).map(patient => (
              <tr key={patient.patientId}>
                <td>{patient.patientId}</td>
                <td>{patient.patientName}</td>
                <td>{patient.age}</td>
                <td>{patient.gender}</td>
                <td>{patient.healthOfficer}</td>
                <td>{patient.doctor}</td>
                <td>{new Date(patient.appointmentTime).toLocaleString()}</td>
                <td>{patient.aadhaarCardNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientList;
