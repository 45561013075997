

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faChevronDown, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from "../../../Assets/O2I_LOGO.png";
import { BASE_URL } from '../../config/apiConfig';
import { Link } from 'react-router-dom';
import Footer from '../../Visitorpage/Visitorfooter.js'; 

const styles = `


.admin-login-container {
  background: linear-gradient(#7CB2D0, #D0E0E5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensures that footer can be positioned absolutely within this container */
}

.login-form-container {
  width: 80%;
  max-width: 500px;
  padding: 60px;
  border-radius: 5px;
  border: 2px solid #0F60B3;
  background-color: transparent;
  box-shadow: 0 2px 5px rgba(255, 0, 0, 0.3);
  margin-bottom: 80px; /* Ensure there's enough space for the footer */
}

.login-form h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-right: 10px;
}

.form-group input, .form-group select {
  padding: 8px;
  border: none;
  border-bottom: 2px solid black;
  width: calc(100% - 30px);
  background: rgba(255, 255, 255, 0.3);
  color: gray;
}

.icon {
  margin-right: 10px;
  font-size: 22px;
}

.error-message {
  color: darkblue;
  margin-top: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 5px;
}

button:hover {
  background-color: #0056b3;
}

.heading-container {
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #007bff;
  color: white;
  text-align: center;
  padding: 10px 0;
}

`;

const styleTag = document.createElement('style');
styleTag.type = 'text/css';
styleTag.appendChild(document.createTextNode(styles));

const AdminLogin = () => {
  const [email, setEmail] = useState(sessionStorage.getItem('savedEmail') || '');
  const [password, setPassword] = useState(sessionStorage.getItem('savedPassword') || '');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [rememberMe, setRememberMe] = useState(sessionStorage.getItem('rememberMe') === 'true');
  const [error, setError] = useState('');
  const [selectedUserType, setSelectedUserType] = useState('patient'); // Default to patient
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const rememberMeValue = sessionStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberMeValue);
  }, []);

  const getJwtToken = () => {
    const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
    return tokenCookie ? tokenCookie.split('=')[1] : null;
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    } else if (id === 'mobileNumber') {
      setMobileNumber(value);
    } else if (id === 'otp') {
      setOtp(value);
    }
  };

  const handleUserTypeChange = (event) => {
    setSelectedUserType(event.target.value);
  };

  const handleSendOtp = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/patients/send-otp?mobileNo=${mobileNumber}`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('OTP sent successfully:', response.data);
      setIsOtpSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Error sending OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/patients/verify-otp?mobileNo=${mobileNumber}&otp=${otp}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('OTP verified successfully:', response.data);
      
      // Store JWT token in local storage
      const { jwtToken, role } = response.data;
      sessionStorage.setItem('jwtToken', jwtToken);
      sessionStorage.setItem('role', role);
      
      navigate('/userdashboard'); // Redirect to user dashboard
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('OTP verification failed. Please try again.');
    }
  };

 const handleSubmit = async (event) => {
  event.preventDefault();

  try {
    let requestBody = {
      userType: selectedUserType,
    };

    if (selectedUserType === 'patient') {
      requestBody = {
        ...requestBody,
        mobile: mobileNumber,
        otp: otp,
      };

      await handleVerifyOtp();
    } else {
      requestBody = {
        ...requestBody,
        email: email,
        password: password,
      };

      const response = await fetch(`${BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      console.log('Response Data:', data);

      // Store JWT token, role, and userId in local storage
      sessionStorage.setItem('jwtToken', data.jwtToken);
      sessionStorage.setItem('role', data.role);
      sessionStorage.setItem('userId', data.userId);  // Save userId in local storage

      const jwtToken = getJwtToken();
      console.log('JWT Token:', jwtToken);

      sessionStorage.setItem('rememberMe', rememberMe.toString());

      if (rememberMe) {
        sessionStorage.setItem('savedEmail', email);
        sessionStorage.setItem('savedPassword', password);
      } else {
        sessionStorage.removeItem('savedEmail');
        sessionStorage.removeItem('savedPassword');
      }

      const userRole = data.role;

      if (userRole.includes('DOCTOR')) {
        navigate('/dashdoc');
      } else if (userRole.includes('HEALTH_OFFICER')) {
        navigate('/dashboard');
      } else if (userRole.includes('SUB_ADMIN')) {
        navigate('/admindashboard');
      } else {
        navigate('/admindashboard');
      }
    }

  } catch (error) {
    console.error('Error:', error.message);
    setError('Login failed. Please check your credentials and try again.');
  }
};


  useEffect(() => {
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  return (
    <div>

   <div className="header">
            <div className="header-text">
                    <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
                </div>
                <div className="buttons-container">
                    <Link to="/registerhospital" className="login-btn">Login/Register</Link>
                    
                </div>
            </div>
<div className="admin-login-container">
      <div className="heading-container">
        <h1>Admin Login</h1>
      </div>
      <div className="login-form-container">
        <div className="form-group">
          <label htmlFor="userType"><FontAwesomeIcon icon={faChevronDown} className="icon" /></label>
          <select
            id="userType"
            value={selectedUserType}
            onChange={handleUserTypeChange}
            required
          >
            <option value="patient">Patient</option>
            <option value="doctor">Doctor</option>
            <option value="health_officer">Health Officer</option>
            <option value="admin">Hospital</option>
          </select>
        </div>
        <form onSubmit={handleSubmit}>
          {selectedUserType === 'patient' ? (
            <>
              <div className="form-group">
                <label htmlFor="mobileNumber"><FontAwesomeIcon icon={faMobileAlt} className="icon" /></label>
                <input
                  type="text"
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={handleInputChange}
                  placeholder="Mobile Number"
                  required
                />
              </div>
              {isOtpSent ? (
                <div className="form-group">
                  <label htmlFor="otp"><FontAwesomeIcon icon={faLock} className="icon" /></label>
                  <input
                    type="password"
                    id="otp"
                    value={otp}
                    onChange={handleInputChange}
                    placeholder="OTP"
                    required
                  />
                </div>
              ) : null}
              <button type="button" onClick={handleSendOtp}>
                Send OTP
              </button>
              {isOtpSent && (
                <button type="submit">
                  Verify OTP and Login
                </button>
              )}
            </>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="email"><FontAwesomeIcon icon={faUser} className="icon" /></label>
                <input
                  type="text"
                  id="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password"><FontAwesomeIcon icon={faLock} className="icon" /></label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handleInputChange}
                  placeholder="Password"
                  required
                />
              </div>
              {/* <div className="form-group">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label htmlFor="rememberMe">Remember me</label>
              </div> */}
              <button type="submit">
                Login
              </button>
            </>
          )}
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
      </div>
        <Footer />
    </div>
  );
};

export default AdminLogin;
