
import React from "react";
import Footer from './Footer';
import Header from "./Header";
import Changepassword from "./changepassword";
import Redirect from "../Components/Redirect";
function Appointment() {
  return (
    <div>
        <Redirect />
      <Header />
      <Changepassword />;
      <Footer />
    </div>
  );
}

export default Appointment;
