//=========================================footer====================================================

import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    background-color: #f8f9fa;
    padding: 50px 0;
`;

const FooterContent = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 14px;
    color: #555;
`;

const FooterSection = styled.div`
    flex: 1;
`;

const FooterHeading = styled.h3`
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
`;

const FooterList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const FooterListItem = styled.li`
    margin-bottom: 10px;
`;

const FooterLink = styled.a`
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: #0056b3;
    }
`;

const FooterText = styled.p`
    line-height: 1.6;
`;

const Footer = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 70, // Adjust as needed for header height
                behavior: "smooth"
            });
        }
    };


    
    return (
        <FooterContainer>
            <FooterContent>
                <FooterSection>
                    <FooterHeading>Contact Us</FooterHeading>
                    <FooterText>
                        Address: B 129, sector 6, noida 201301 <br />
                        Email: o2i@gmail.com <br />
                        Phone: +1234567890
                    </FooterText>
                </FooterSection>
              <FooterSection>
                    <FooterHeading>Legal</FooterHeading>
                    <FooterList>
                        <FooterListItem><FooterLink onClick={() => scrollToSection('privacy-policy')}>Privacy Policy</FooterLink></FooterListItem>
                        <FooterListItem><FooterLink onClick={() => scrollToSection('terms-conditions')}>Terms & Conditions</FooterLink></FooterListItem>
                        <FooterListItem><FooterLink href="#">Cookie Policy</FooterLink></FooterListItem>
                    </FooterList>
                   </FooterSection>
                 <FooterSection>
                    <FooterHeading>Social</FooterHeading>
                    <FooterList>
                       <FooterListItem><FooterLink href="#"><i className="fab fa-facebook-f"></i> Facebook</FooterLink></FooterListItem>
                        <FooterListItem><FooterLink href="#"><i className="fab fa-twitter"></i> Twitter</FooterLink></FooterListItem>
                        <FooterListItem><FooterLink href="#"><i className="fab fa-instagram"></i> Instagram</FooterLink></FooterListItem>
                    </FooterList>
                </FooterSection>
            </FooterContent>
            <div style={{ textAlign: 'center', marginTop: '20px', color: '#555', fontSize: '14px' }}>
                &copy; {new Date().getFullYear()} Your Company. All rights reserved.
            </div>
        </FooterContainer>
    );
};

export default Footer;
