import React from 'react';
import { useParams } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
const VideoHome = () => {
  const { id } = useParams(); // Correctly using useParams to get the 'id' parameter
  const roomID =  id;

      let myMeeting = async (element) => {
     // generate Kit Token
      const appID = 709268713;
      const serverSecret = "5a24fd89403d07d27d7640c1c28be33d";
    //   const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  Date.now().toString(),   "o2i");

const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
  appID,
  serverSecret,
  roomID,
  Date.now().toString(), // Convert timestamp to string using toString()
  "o2i"
);

     // Create instance object from Kit Token.
      const zp = ZegoUIKitPrebuilt.create(kitToken);
      // start the call
      zp.joinRoom({
        container: element,
        sharedLinks: [
          {
            name: 'Personal link',
            url:
             window.location.protocol + '//' + 
             window.location.host + window.location.pathname +
              '?roomID=' +
              roomID,
          },
        ],
        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        },
      });

    }


  return (
    <div ref={myMeeting}>
     
    </div>
  )
}

export default VideoHome
