
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import Videoheader from "./Video/Videoheader";
import { BASE_URL } from './config/apiConfig';
import Redirect from "../Components/Redirect";
const AppStyle = {
  background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
};

const todayPatientListStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};

const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#DCE7EB',
  border: '2px solid #104E8D',
  borderBottom: '2px solid #104E8D',
  overflowY: 'auto',
  marginBottom: '10px'
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  border: '2px solid #D9D9D9',
};

const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff',
  borderRight: '2px solid #fff',
};

const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd',
};

const lastColumnStyle = {
  borderRight: '2px solid #fff',
};

const columnStyles = {
  patient: { maxWidth: '100px' },
  doctor: { maxWidth: '80px' },
  remarks: { maxWidth: '150px' },
  appointmentTime: { maxWidth: '100px' },
  status: { maxWidth: '100px' },
  action: { maxWidth: '100px' },
};

function AppointmentHistory() {
  const [range, setRange] = useState("1-2");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const key = sessionStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in sessionStorage");
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/registerDocHs/get-all-patients`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`
          }
        });
        const result = await response.json();
        setData(result || []); // Ensure data is set to an empty array if null
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]); // Set data to an empty array on error
      }
    };

    fetchData();
  }, []);

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const handleVideoCallClick = (patientId) => {
    navigate('/legal', { state: { patientId } });
  };
  const filteredData = data.filter((item) =>
    item.patientName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const rowHeight = 45;
  const numberOfDataRows = 10;
  const headerHeight = 100;
  const totalRows = data.length;
  const displayedRows = Math.max(totalRows, numberOfDataRows);
  const containerHeight = headerHeight + rowHeight * displayedRows;

  return (
    <div style={AppStyle}>
        <Redirect />
      <Header />
      <Videoheader />
      <div style={todayPatientListStyle}>
        Today’s Patient List
      </div>
      <div style={{ ...tableContainerStyle, height: containerHeight, position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-10">1-10</option>
              <option value="10-20">10-20</option>
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
          <input
            type="text"
            style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }}
            value={searchQuery} // Bind input to searchQuery state
            onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery state on input change
          />
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={{ ...thStyle, ...columnStyles.patient }}>Patient Name</th>
              <th style={{ ...thStyle, ...columnStyles.doctor }}>Doctor</th>
              <th style={{ ...thStyle, ...columnStyles.remarks }}>Centre</th>
              <th style={{ ...thStyle, ...columnStyles.appointmentTime }}>Mobile</th>
              <th style={{ ...thStyle, ...columnStyles.status }}>Date</th>
              <th style={{ ...thStyle, ...columnStyles.status }}>Status</th>
              <th style={{ ...thStyle, ...columnStyles.action, ...lastColumnStyle }}>Consultation</th>
            </tr>
          </thead>
          <tbody>
  {filteredData.length > 0 ? (
    filteredData.map((item, index) => (
      <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
        <td style={{ ...tdStyle, ...columnStyles.patient }}>{item.patientName || ''}</td>
        <td style={{ ...tdStyle, ...columnStyles.doctor }}>{item.doctor || ''}</td>
        <td style={{ ...tdStyle, ...columnStyles.remarks }}>{item.hospital || ''}</td>
        <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}>{item.mobileNo || ''}</td>
        <td style={{ ...tdStyle, ...columnStyles.status }}>{item.localDate || ''}</td>
        <td style={{ ...tdStyle, ...columnStyles.status }}>{item.status ? "Confirmed" : "Pending"}</td>
        <td style={{ ...tdStyle, ...columnStyles.status }}>
          <FontAwesomeIcon
            icon={faVideo}
            style={{ cursor: 'pointer', color: '#104E8D', fontSize: '24px' }}
            onClick={() => handleVideoCallClick(item.patientId)}
          />
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="7" style={{ height: rowHeight, backgroundColor: '#D9D9D9', textAlign: 'center' }}>
        No patients found.
      </td>
    </tr>
  )}
  {/* Fill empty rows if needed */}
  {[...Array(numberOfDataRows - filteredData.length)].map((_, index) => (
    <tr key={index + filteredData.length} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
      <td style={{ ...tdStyle, ...columnStyles.patient }}></td>
      <td style={{ ...tdStyle, ...columnStyles.doctor }}></td>
      <td style={{ ...tdStyle, ...columnStyles.remarks }}></td>
      <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}></td>
      <td style={{ ...tdStyle, ...columnStyles.status }}></td>
      <td style={{ ...tdStyle, ...columnStyles.status }}></td>
      <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }}></td>
    </tr>
  ))}
</tbody>

        </table>
      </div>
      <Footer />
    </div>
  );
}

export default AppointmentHistory;
