
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faVideo, faHistory, faList } from '@fortawesome/free-solid-svg-icons';
// import "../Styles/Dashdoc.css";
// import Footer from "./Footer"; 
// import doctorImage from "../Assets/docimage.png";
// import historyIcon from "../Assets/historyIcon.png"; 
// import Videomessage from "../Assets/Video Message.png";
// import Timesheet from "../Assets/Timesheet.png"; 
// import TodayPatientList from "../Assets/TodayPatientList.png"; 
// import Header from "./Header";
// import { BASE_URL } from './config/apiConfig';

// const Dashdoc = () => {
//   const [doctorInfo, setDoctorInfo] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchDoctorDetails();
//   }, []);

//   const fetchDoctorDetails = async () => {
//     try {
//       const key = sessionStorage.getItem('jwtToken');

//       if (!key) {
//         console.error('No JWT token found in sessionStorage');
//         return;
//       }

//       const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
//         headers: {
//           'Auth': `Bearer ${key}`
//         }
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }

//       const data = await response.json();
//       setDoctorInfo(data);
//     } catch (error) {
//       console.error('Error fetching doctor info:', error);
//       // Handle error
//     }
//   };

//   const handleVideoConsultationClick = () => {
//     navigate('/legal');
//   };

//   const handleAppointmentHistoryClick = () => {
//     navigate('/drappoinment');
//   };

//   const handleCaseHistoryClick = () => {
//     navigate('/casehistory');
//   };

//   const handleTodayPatientListClick = () => {
//     navigate('/drpatientlist');
//   };

//   return (
//     <div className="dashdoc-container">
//       <Header />
//       <div className="background-image"></div>
//       <div className="doctor-info-box">
//         <h2>Welcome Back,</h2>
//         {doctorInfo && (
//           <>
//             <h3>Dr.{doctorInfo.name}</h3>
//             <p>{doctorInfo.specialist.charAt(0).toUpperCase() + doctorInfo.specialist.slice(1)}</p>

//             {/* <p>Role: {doctorInfo.role}</p> */}
//           </>
//         )}
//       </div>

//       <div className="doctor-image-container">
//         <img src={doctorImage} alt="Doctor" className="doctor-image" />
//       </div>

//       <div className="cart-container">
//         <div className="cart dark-blue-bg" onClick={handleVideoConsultationClick}>
//           <div className="icon red-icon">
//             <img src={Videomessage} alt="Video Message Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Video Consultation</p>
//           </div>
//         </div>

//         {/* <div className="cart dark-blue-bg" onClick={handleCaseHistoryClick}>
//           <div className="icon red-icon">
//             <img src={historyIcon} alt="History Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Patient case History</p>
//           </div>
//         </div> */}

//         <div className="cart dark-blue-bg" onClick={handleAppointmentHistoryClick}>
//           <div className="icon red-icon">
//             <img src={Timesheet} alt="Timesheet Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Appointment History</p>
//           </div>
//         </div>

//         <div className="cart dark-blue-bg" onClick={handleTodayPatientListClick}>
//           <div className="icon red-icon">
//             <img src={TodayPatientList} alt="History Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Today’s Patient List</p>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </div>
//   );
// };

// export default Dashdoc;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Styles/Dashdoc.css";
import Footer from "./Footer"; 
import doctorImage from "../Assets/docimage.png";
import Videomessage from "../Assets/Video Message.png";
import Timesheet from "../Assets/Timesheet.png";
import TodayPatientList from "../Assets/TodayPatientList.png";
import Header from "./Header";
import { BASE_URL } from './config/apiConfig';
import Redirect from "../Components/Redirect";
const Dashdoc = () => {
  const [doctorInfo, setDoctorInfo] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    fetchDoctorDetails();
  }, []);

 const fetchDoctorDetails = async () => {
  try {
    const key = sessionStorage.getItem('jwtToken');

    if (!key) {
      console.error('No JWT token found in sessionStorage');
      return;
    }

    const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
      headers: {
        'Auth': `Bearer ${key}`, // Use 'Authorization' for the header key
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();

    // Capitalize the first letter of the doctor's name and specialist
    const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
    const capitalizedSpecialist = data.specialist ? data.specialist.charAt(0).toUpperCase() + data.specialist.slice(1) : '';

    setDoctorInfo({
      name: capitalizedDoctorName,
      specialist: capitalizedSpecialist,
      role: data.role
    }); // Update the state with the capitalized names
  } catch (error) {
    console.error('Error fetching doctor info:', error);
    // Handle error
  }
};


  const handleVideoConsultationClick = () => {
    navigate('/legal');
  };

  const handleAppointmentHistoryClick = () => {
    navigate('/drappoinment');
  };

  const handleCaseHistoryClick = () => {
    navigate('/casehistory');
  };

  const handleTodayPatientListClick = () => {
    navigate('/drpatientlist');
  };

  return (
    <div className="dashdoc-container">
        <Redirect />
      <Header />
      <div className="background-image"></div>
      <div className="doctor-info-box">
  <h2>Welcome Back,</h2>
  {doctorInfo && (
    <>
      <h3>Dr. {doctorInfo.name || 'Name not available'}</h3> {/* Fallback text */}
      <p>{doctorInfo.specialist || 'Specialist not available'}</p> {/* Fallback text */}
    </>
  )}
</div>


      <div className="doctor-image-container">
        <img src={doctorImage} alt="Doctor" className="doctor-image" />
      </div>

      <div className="cart-container">
        <div className="cart dark-blue-bg" onClick={handleVideoConsultationClick}>
          <div className="icon red-icon">
            <img src={Videomessage} alt="Video Message Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Video Consultation</p>
          </div>
        </div>

        <div className="cart dark-blue-bg" onClick={handleAppointmentHistoryClick}>
          <div className="icon red-icon">
            <img src={Timesheet} alt="Timesheet Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Appointment History</p>
          </div>
        </div>

        <div className="cart dark-blue-bg" onClick={handleTodayPatientListClick}>
          <div className="icon red-icon">
            <img src={TodayPatientList} alt="Today’s Patient List Icon" className="history-icon" />
          </div>
          <div className="content">
            <p>Today’s Patient List</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashdoc;
