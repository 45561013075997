// apiConfig.js

     //const BASE_URL = 'https://142.93.221.34:7075'; // Modify this according to your server configuration
//  const BASE_URL = 'http://localhost:7075'; // Modify this according to your server configuration
 const BASE_URL =  'https://apis.telemed.fun';// Modify this according to your server configuration
export default BASE_URL;

export { BASE_URL };












