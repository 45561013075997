


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../Hoheader'; // Assuming Header component file is imported here
import Footer from '../../Footer'; // Adjust path based on your project structure
import BASE_URL from '../../config/apiConfig';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Height } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Redirect from "../../../Components/Redirect";
const AadhaarForm = () => {
    const [formData, setFormData] = useState({
        aadhaarNumber: '',
        patientId: '',
        patientName: '',
        height: '',
        weight: '',
        age: '',
        gender: 'male',
        mobileNo: '',
        email: '',
        medicalInfo: {
            bloodGroup: '',
            spO2: '',
            respirationRate: '',
            bloodSugar: '',
            diastolicBP: '',
            systolicBP: '',
            pulseRate: '',
            temperature: '',
            hemoglobin: '',
            comorbidity: '',
            complaints: '',
            files: [],
            otherIllness: '',
            allergy: '',
            heartRate: '',
            doctor: '', // Ensure doctor field is properly initialized
        }
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const [selectedDoctorEmail, setSelectedDoctorEmail] = useState(''); // State for selected doctor's email
    const [selectedDoctorName, setSelectedDoctorName] = useState(''); // State for selected doctor's name
    const handleFileChange = (event) => {
        const filesArray = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    };

    const handleFileRemove = (fileName) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const showError = (errorMessage) => {
        setError(errorMessage);
        setTimeout(() => closeMessage(), 3000);
    };

    const showSuccess = (successMessage) => {
        setSuccess(successMessage);
        setTimeout(() => closeMessage(), 3000);
    };

    const closeMessage = () => {
        setError(null);
        setSuccess(null);
    };

    const toggleCurrentMedicines = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleMedicalInfoChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            medicalInfo: {
                ...prevFormData.medicalInfo,
                [name]: value
            }
        }));
    };

    //===================================================get doctors list=======================

    const fetchDoctors = async () => {
        const key = sessionStorage.getItem("jwtToken");
        try {
            const response = await fetch(`${BASE_URL}/registerDocHs/doctors`, {
                headers: {
                    'Content-Type': 'application/json',
                   'Auth': `Bearer ${key}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch doctors');
            }
            const data = await response.json();
            setDoctors(data); // Assuming data is an array of doctors
            setFilteredDoctors(data); // Set filtered doctors initially to all doctors
        } catch (error) {
            console.error('Error fetching doctors:', error);
            setError('Failed to fetch doctors');
        }
    };

    // useEffect to fetch doctors on component mount
    useEffect(() => {
        fetchDoctors();
    }, []);

    // Function to handle selecting a doctor
    const handleDoctorSelect = (event) => {
        const selectedEmail = event.target.value;
        setSelectedDoctorEmail(selectedEmail); // Save selected doctor's email in state

        // Find the selected doctor's name from the doctors array
        const selectedDoctor = doctors.find(doctor => doctor.email === selectedEmail);
        if (selectedDoctor) {
            setSelectedDoctorName(selectedDoctor.name); // Update selected doctor's name in state
        } else {
            setSelectedDoctorName(''); // Reset selected doctor's name if not found
        }

        // Update formData with selected doctor's email
        setFormData(prevFormData => ({
            ...prevFormData,
            medicalInfo: {
                ...prevFormData.medicalInfo,
                doctor: selectedEmail // Update doctor email in formData
            }
        }));
    };

    // Function to handle changes in medical info textarea
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    //======================================================get api using aadhar card number ====================================
    const handleSearch = async () => {
        try {
            const key = sessionStorage.getItem("jwtToken");
            if (!key) {
                console.error("No JWT token found in sessionStorage");
                return;
            }

            const response = await axios.get(`${BASE_URL}/registerDocHs/patient?addharNo=${formData.aadhaarNumber}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `Bearer ${key}`
                }
            });
            const { patientId, patientName, weight, height, age, gender, email, mobileNo } = response.data;
            setFormData({
                ...formData,
                patientId, // Save the patientId
                patientName,
                height,
                weight,
                age,
                gender,
                email,
                mobileNo
            });
            setError(null);
        } catch (error) {
            setError(error.message);
            setFormData({
                ...formData,
                patientId: '', // Reset patientId
                patientName: '',
                height: '',
                weight: '',
                age: '',
                gender: 'male',
                email: '',
                mobileNo: ''
            });
        }
    };
    //=====================================medical info saved info by post api =============================================


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const key = sessionStorage.getItem("jwtToken");
            if (!key) {
                console.error("No JWT token found in sessionStorage");
                return;
            }

            const formDataToSubmit = new FormData();
            Object.keys(formData.medicalInfo).forEach((key) => {
                if (key !== 'files') {
                    formDataToSubmit.append(key, formData.medicalInfo[key]);
                }
            });

            selectedFiles.forEach((file) => {
                formDataToSubmit.append('files', file);
            });

            formDataToSubmit.append('doctorEmail', selectedDoctorEmail);

            await axios.post(`${BASE_URL}/registerDocHs/save-patient-detail?patientId=${formData.patientId}`, formDataToSubmit, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Auth': `Bearer ${key}`
                }
            });

            console.log("Medical information saved successfully!");

            // Reset form after successful submission
            setFormData({
                aadhaarNumber: '',
                patientId: '',
                patientName: '',
                height: '',
                weight: '',
                age: '',
                gender: 'male',
                mobileNo: '',
                email: '',
                medicalInfo: {
                    bloodGroup: '',
                    spO2: '',
                    respirationRate: '',
                    bloodSugar: '',
                    diastolicBP: '',
                    systolicBP: '',
                    pulseRate: '',
                    temperature: '',
                    hemoglobin: '',
                    comorbidity: '',
                    complaints: '',
                    files: [],
                    otherIllness: '',
                    allergy: '',
                    heartRate: '',
                    doctor: '', // Ensure doctor field is properly initialized
                }
            });
            setSelectedFiles([]);
            setSuccess('Medical information saved successfully!'); // Set success message
            setError(null); // Clear any previous error message

            // Reset selected doctor fields
            setSelectedDoctorEmail('');
            setSelectedDoctorName('');

        } catch (error) {
            console.error("Error saving medical information:", error);
            setError('Failed to save medical information'); // Set error message
            setSuccess(null); // Clear any previous success message
        }
    };

    //========================================display the deata on page =================================================
    return (
        <div style={styles.pageContainer}>
              <Redirect />
            <Header /> {/* Include the Header component */}
            <div style={styles.contentContainer}>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2 style={{ fontSize: '50px', color: 'rgb(50, 78, 106)', textShadow: '0px 0px 32.1px rgb(187, 17, 17)' }}>Pre-Consulting</h2>
                </div>

                {/* Aadhaar Card Number */}
                <div style={styles.inputContainer}>
                    <label htmlFor="aadhaarNumber" style={{ marginRight: '10px', borderRadius: '5px', backgroundColor: '#0F60B3', maxWidth: '200px', padding: '10px', color: '#ffffff' }}>Aadhaar Number:</label>

                    <input
                        type="text"
                        id="aadhaarNumber"
                        name="aadhaarNumber"
                        value={formData.aadhaarNumber}
                        onChange={handleChange}
                        style={styles.input}
                    />
                    <button
                        type="button"
                        onClick={handleSearch}
                        style={styles.searchButton}
                    >
                        Search
                    </button>
                </div>


                {/* //=========================================== Personal Information============================================== */}


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#0F60B3', padding: '20px', borderRadius: '5px', marginBottom: '20px', color: 'white' }}>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        <label htmlFor="patientName">Patient Name:</label>
                        <input type="text" id="patientName" name="patientName" value={formData.patientName} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                    </div>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        <label htmlFor="height">Height (cm):</label>
                        <input type="text" id="height" name="height" value={formData.height} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                    </div>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        <label htmlFor="weight">Weight (kg):</label>
                        <input type="text" id="weight" name="weight" value={formData.weight} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                    </div>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        <label htmlFor="age">Age:</label>
                        <input type="number" id="age" name="age" value={formData.age} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                    </div>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        <label htmlFor="gender">Gender:</label>
                        <select id="gender" name="gender" value={formData.gender} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }}>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        <label htmlFor="mobileNo">Mobile No.:</label>
                        <input type="tel" id="mobileNo" name="mobileNo" value={formData.mobileNo} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                    </div>
                    <div style={{ flex: '1' }}>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                    </div>
                </div>

                {/* //=======================================================Medical Information =============================================== */}


                <div style={{ background: '#0F60B3', padding: '20px', borderRadius: '5px', marginBottom: '20px', color: 'white' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="bloodGroup">Blood Group:</label>
                            <input type="text" id="bloodGroup" name="bloodGroup" value={formData.medicalInfo.bloodGroup} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="spO2">Spo2(%):</label>
                            <input type="text" id="spO2" name="spO2" value={formData.medicalInfo.spO2} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="respirationRate">respirationRate(/min):</label>
                            <input type="text" id="respirationRate" name="respirationRate" value={formData.medicalInfo.respirationRate} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="bloodSugar">Blood Sugar(mg/dl):</label>
                            <input type="text" id="bloodSugar" name="bloodSugar" value={formData.medicalInfo.bloodSugar} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="heartRate">Heart Rate (BPM):</label>
                            <input type="text" id="heartRate" name="heartRate" value={formData.medicalInfo.heartRate} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>

                    </div>

                    {/* Second Line */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="diastolicBP">Diastolic BP (mmHg):</label>
                            <input type="text" id="diastolicBP" name="diastolicBP" value={formData.medicalInfo.diastolicBP} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="systolicBP">Systolic BP (mmHg):</label>
                            <input type="text" id="systolicBP" name="systolicBP" value={formData.medicalInfo.systolicBP} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="pulseRate">Pulse Rate (/min):</label>
                            <input type="text" id="pulseRate" name="pulseRate" value={formData.medicalInfo.pulseRate} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="temperature">Temperature (°C):</label>
                            <input type="text" id="temperature" name="temperature" value={formData.medicalInfo.temperature} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="hemoglobin">Hemoglobin (gm/dl):</label>
                            <input type="text" id="hemoglobin" name="hemoglobin" value={formData.medicalInfo.hemoglobin} onChange={handleMedicalInfoChange} style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }} />
                        </div>
                    </div>

                    {/* Third Line */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="comorbidity">Comorbidity:</label>
                            <textarea
                                id="comorbidity"
                                name="comorbidity"
                                value={formData.medicalInfo.comorbidity}
                                onChange={handleMedicalInfoChange}
                                style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px', resize: 'vertical' }}
                            />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="complaints">Complaints:</label>
                            <textarea
                                id="complaints"
                                name="complaints"
                                value={formData.medicalInfo.complaints}
                                onChange={handleMedicalInfoChange}
                                style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px', resize: 'vertical' }}
                            />
                        </div>
                        <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px' }}>
                            <label htmlFor="allergy">Allergy:</label>
                            <textarea
                                id="allergy"
                                name="allergy"
                                value={formData.medicalInfo.allergy}
                                onChange={handleMedicalInfoChange}
                                style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px', resize: 'vertical' }}
                            />
                        </div>



                        <div>
                            <label>Select Doctor:</label>
                            <select
                                value={selectedDoctorEmail}
                                onChange={handleDoctorSelect}
                                style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px' }}
                            >
                                <option value="">Select a doctor</option>
                                {doctors.map((doctor) => (
                                    <option key={doctor.email} value={doctor.email}>
                                        {doctor.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>


                    {/* Fourth Line */}

                </div>

                <div style={{ display: 'flex', marginBottom: '10px', background: '#0F60B3', width: '100%', borderRadius: '5px' }}>
                    <div style={{ flex: '1', marginRight: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="otherIllness" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', color: '#ffffff', padding: '8px', borderRadius: '5px', width: '100%' }} onClick={toggleCurrentMedicines}>
                            Enter details of any medicines being taken currently
                            <AddCircleIcon style={{ marginLeft: '5px', color: 'red', fontSize: '26px' }} />
                        </label>

                        {isOpen && (
                            <textarea
                                id="otherIllness"
                                name="otherIllness"
                                value={formData.medicalInfo.otherIllness}
                                onChange={handleMedicalInfoChange}
                                style={{ width: '100%', borderRadius: '5px', background: '#DADADA', padding: '8px', resize: 'vertical' }}
                            />
                        )}
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                    {/* Left side: Attach Documents */}

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <label htmlFor="attachments" style={{ color: '#ffffff', padding: '8px', borderRadius: '5px', background: '#0F60B3', marginBottom: '0', display: 'flex', alignItems: 'center' }}>
                            Attach here any other documents related to your health:
                            <i className="fas fa-plus" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                        </label>
                        <input
                            type="file"
                            id="attachments"
                            name="attachments"
                            multiple
                            onChange={handleFileChange}
                            style={{ borderRadius: '5px', background: '#DADADA', padding: '8px', marginRight: '10px' }}
                        />
                        {selectedFiles.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                <h4 style={{ fontSize: '13px', marginBottom: '5px' }}>Selected Files:</h4>
                                <ul>
                                    {selectedFiles.map((file, index) => (
                                        <li key={index}>
                                            {file.name}
                                            <button style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }} onClick={() => handleFileRemove(file.name)}>
                                                <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <button
                            type="button"
                            onClick={handleSubmit}
                            style={{ padding: '10px 20px', background: '#28A745', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginLeft: '10px' }}
                        >
                            Save
                        </button>
                    </div>



                </div>






                <div style={{ position: 'relative' }}>
                    {/* Buttons to trigger error and success messages */}
                    {error && (
                        <div
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                padding: '20px',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                zIndex: '1000',
                                textAlign: 'center',
                                maxWidth: '300px',
                            }}
                        >
                            <div style={{ marginBottom: '10px', color: 'red', fontSize: '18px' }}>
                                Error
                            </div>
                            <div style={{ marginBottom: '20px', color: 'red' }}>{error}</div>
                            <button
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: 'grey',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                }}
                                onClick={closeMessage}
                            >
                                &#10006;
                            </button>
                        </div>
                    )}

                    {/* Success message popup */}
                    {success && (
                        <div
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                padding: '20px',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                zIndex: '1000',
                                textAlign: 'center',
                                maxWidth: '300px',
                            }}
                        >
                            <div style={{ marginBottom: '10px', color: 'green', fontSize: '18px' }}>
                                Success
                            </div>
                            <div style={{ marginBottom: '20px', color: 'green' }}>{success}</div>
                            <button
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: 'grey',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                }}
                                onClick={closeMessage}
                            >
                                &#10006;
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <Footer /> {/* Include the Footer component */}
        </div>
    );
};
//===========================css ==============================================================
const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        minspO2: '100vh',
        background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5, #E5EFF3)',
    },
    contentContainer: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
    },
    header: {
        width: '100%',
        textAlign: 'center',
        marginBottom: '20px',
    },
    heading: {
        color: '#007BFF',
        fontSize: '24px',
        marginBottom: '10px',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    label: {
        marginRight: '10px',
        fontrespirationRate: 'bold',
    },
    input: {
        width: '200px',
        padding: '8px',
        borderRadius: '5px',
        background: '#DADADA',
        border: 'none',
        marginRight: '10px',
    },
    searchButton: {
        padding: '8px 16px',
        background: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default AadhaarForm;
