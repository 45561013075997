
// // // import React, { useEffect, useRef, useState } from 'react';
// // // import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// // // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // // import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'; // Import the icons
// // // import './Videocallcs.css';

// // // const PersistentVideoCall = ({ roomID }) => {
// // //   const videoCallContainer = useRef(null);
// // //   const [isMinimized, setIsMinimized] = useState(false);
// // //   const [userRole, setUserRole] = useState('');

// // //   useEffect(() => {
// // //     if (!roomID) return;

// // //     const appID = 709268713;
// // //     const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
// // //     const roomIDString = String(roomID);

// // //     // Fetch doctor's name from sessionStorage or use a default value
// // //     const Name = sessionStorage.getItem('Name') || 'Doctor';

// // //     // Use doctor's name instead of 'o2i'
// // //     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
// // //       appID,
// // //       serverSecret,
// // //       roomIDString,
// // //       Date.now().toString(),
// // //       Name  // Replaced 'o2i' with doctorName
// // //     );

// // //     const zp = ZegoUIKitPrebuilt.create(kitToken);

// // //     zp.joinRoom({
// // //       container: videoCallContainer.current,
// // //       scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
// // //       turnOnMicrophoneWhenJoining: true,
// // //       turnOnCameraWhenJoining: true,
// // //       showMyCameraToggleButton: true,
// // //       showMyMicrophoneToggleButton: true,
// // //       showAudioVideoSettingsButton: false,
// // //       showTextChat: true,
// // //       showUserList: false,
// // //       maxUsers: 2,
// // //       layout: "Auto",
// // //       showLayoutButton: false,
// // //       showPreJoinView: false,
// // //       showScreenSharingButton: false,
// // //       onLeaveRoom: () => {
// // //         handleLeaveRoom();
// // //       }
// // //     });

// // //     return () => {
// // //       zp.leaveRoom();
// // //     };
// // //   }, [roomID]);

// // //   const handleMinimize = () => {
// // //     setIsMinimized(!isMinimized);
// // //   };

// // //   console.log('User Role customized:', userRole);

  // const handleLeaveRoom = () => {
  //   sessionStorage.removeItem('kitToken');
  //   sessionStorage.removeItem('roomID');
  //   sessionStorage.removeItem('z_appconfig_709268713_1');
  //   sessionStorage.removeItem('z_appconfig_etag_709268713_1');
  //   sessionStorage.removeItem('z_deviceID');
  //   sessionStorage.removeItem('z_net_access_servers_709268713_1');
  //   sessionStorage.removeItem('z_rtc_domains_709268713_1');
  //   sessionStorage.removeItem('z_s_c_709268713');
  //   sessionStorage.removeItem('z_store_keys');
  //   sessionStorage.removeItem('callDeclined');
  //   sessionStorage.removeItem('patientDetails_3');
  //   sessionStorage.removeItem('patientId');
  //   sessionStorage.removeItem('Name');
  //   sessionStorage.removeItem('patientName');
  //   const userRole1 = sessionStorage.getItem('role');
  //   console.log('Current userRole:', userRole1);
  //   if (userRole1 === '[HEALTH_OFFICER]') {
  //     setTimeout(() => {
  //       window.location.href = '/list';
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 100);
  //     }, 100);
  //   } else if (userRole1 === '[DOCTOR]') {
  //     sessionStorage.removeItem('userId');
  //     setTimeout(() => {
  //       window.location.href = '/legal';
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 100);
  //     }, 100);
  //   } else {
  //     console.error('Unknown user role:', userRole);
  //   }
  // };

// // //   return (
// // //     <>
// // //       <div
// // //         ref={videoCallContainer}
// // //         className={isMinimized ? 'minimized-video' : 'full-video'}
// // //       ></div>
// // //       <button onClick={handleMinimize} className="minimize-button">
// // //         {isMinimized ? (
// // //           <FontAwesomeIcon icon={faExpandAlt} /> 
// // //         ) : (
// // //           <FontAwesomeIcon icon={faCompressAlt} />  
// // //         )}
// // //       </button>
// // //     </>
// // //   );
// // // };

// // // export default PersistentVideoCall;


// // //===========================26=09=2024================================
// // import React, { useEffect, useRef, useState } from 'react';
// // import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
// // import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
// // import './Videocallcs.css';
// // import { BASE_URL } from '../config/apiConfig';

// // const PersistentVideoCall = ({ roomID }) => {
// //   const videoCallContainer = useRef(null);
// //   const [isMinimized, setIsMinimized] = useState(false);
// //   const [userRole, setUserRole] = useState('');
// //   const location = useLocation();
// //   const navigate = useNavigate(); // Get navigate using useNavigate hook

// //   useEffect(() => {
// //     const storedUserRole = sessionStorage.getItem('role');
// //     setUserRole(storedUserRole);

// //     if (!roomID) {
// //       console.error('No roomID provided');
// //       return;
// //     }

// //     const appID = 709268713;
// //     const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
// //     const roomIDString = String(roomID);
// //     const Name = sessionStorage.getItem('Name') || 'Doctor';

// //     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
// //       appID,
// //       serverSecret,
// //       roomIDString,
// //       Date.now().toString(),
// //       Name
// //     );

// //     const zp = ZegoUIKitPrebuilt.create(kitToken);

// //     zp.joinRoom({
// //       container: videoCallContainer.current,
// //       scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
// //       turnOnMicrophoneWhenJoining: true,
// //       turnOnCameraWhenJoining: true,
// //       showMyCameraToggleButton: true,
// //       showMyMicrophoneToggleButton: true,
// //       showAudioVideoSettingsButton: false,
// //       showTextChat: true,
// //       showUserList: false,
// //       maxUsers: 2,
// //       layout: 'Auto',
// //       showLayoutButton: false,
// //       showPreJoinView: false,
// //       showScreenSharingButton: false,
// //       onLeaveRoom: () => {
// //         handleLeaveRoom();
// //       },
// //     });

// //     return () => {
// //       if (zp && zp.destroy) {
// //         zp.destroy();
// //       }
// //     };
// //   }, [roomID]);

// //   useEffect(() => {
// //     const handleMinimizeBasedOnPath = () => {
// //       const currentPath = location.pathname;

// //       // Set minimization logic based on the user role and path
// //       if (userRole === '[HEALTH_OFFICER]' && currentPath !== `${BASE_URL}/hovideo`) {
// //         setIsMinimized(true);
// //       } else if (userRole === '[DOCTOR]' && currentPath !== `${BASE_URL}/legal`) {
// //         setIsMinimized(true);
// //       } else {
// //         setIsMinimized(false);
// //       }
// //     };

// //     // Call the function initially
// //     handleMinimizeBasedOnPath();

// //     // Add listener for pathname changes
// //     const unlisten = () => {
// //       handleMinimizeBasedOnPath();
// //     };

// //     // Clean up the listener on component unmount
// //     return () => {
// //       unlisten();
// //     };
// //   }, [location.pathname, userRole]);

// //   const handleMinimize = () => {
// //     setIsMinimized(!isMinimized);
// //   };

// //   const handleLeaveRoom = () => {
// //     sessionStorage.clear();
// //     if (userRole === '[HEALTH_OFFICER]') {
// //       setTimeout(() => {
// //         navigate('/list'); // Use navigate for redirection
// //       }, 100);
// //     } else if (userRole === '[DOCTOR]') {
// //       setTimeout(() => {
// //         navigate('/legal'); // Use navigate for redirection
// //       }, 100);
// //     } else {
// //       console.error('Unknown user role:', userRole);
// //     }
// //   };

// //   return (
// //     <>
// //       <div
// //         ref={videoCallContainer}
// //         className={isMinimized ? 'minimized-video' : 'full-video'}
// //         style={{ width: isMinimized ? '200px' : '100%', height: isMinimized ? '100px' : '500px' }}
// //       >
// //         {isMinimized ? <p>Video Minimized</p> : <p>Video Active</p>}
// //       </div>
// //       <button onClick={handleMinimize} className="minimize-button">
// //         {isMinimized ? (
// //           <FontAwesomeIcon icon={faExpandAlt} />
// //         ) : (
// //           <FontAwesomeIcon icon={faCompressAlt} />
// //         )}
// //       </button>
// //     </>
// //   );
// // };

// // export default PersistentVideoCall;
// // import React, { useEffect, useRef, useState } from 'react';
// // import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import './Videocallcs.css';
// // import { BASE_URL } from '../config/apiConfig';

// // const PersistentVideoCall = ({ roomID }) => {
// //   const videoCallContainer = useRef(null);
// //   const [isMinimized, setIsMinimized] = useState(false);
// //   const [userRole, setUserRole] = useState('');
// //   const location = useLocation();
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     const storedUserRole = sessionStorage.getItem('role');
// //     setUserRole(storedUserRole);

// //     if (!roomID) {
// //       console.error('No roomID provided');
// //       return;
// //     }

// //     const appID = 709268713;
// //     const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
// //     const roomIDString = String(roomID);
// //     const Name = sessionStorage.getItem('Name') || 'Doctor';

// //     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
// //       appID,
// //       serverSecret,
// //       roomIDString,
// //       Date.now().toString(),
// //       Name
// //     );

// //     const zp = ZegoUIKitPrebuilt.create(kitToken);

// //     zp.joinRoom({
// //       container: videoCallContainer.current,
// //       scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
// //       turnOnMicrophoneWhenJoining: true,
// //       turnOnCameraWhenJoining: true,
// //       showMyCameraToggleButton: true,
// //       showMyMicrophoneToggleButton: true,
// //       showAudioVideoSettingsButton: false,
// //       showTextChat: true,
// //       showUserList: false,
// //       maxUsers: 2,
// //       layout: 'Auto',
// //       showLayoutButton: false,
// //       showPreJoinView: false,
// //       showScreenSharingButton: false,
// //       onLeaveRoom: () => {
// //         handleLeaveRoom();
// //       },
// //     });

// //     return () => {
// //       if (zp && zp.destroy) {
// //         zp.destroy();
// //       }
// //     };
// //   }, [roomID]);

// //   useEffect(() => {
// //     const handleMinimizeBasedOnPath = () => {
// //       const currentPath = location.pathname;
// //       console.log(`Current Path: ${currentPath}`); // Log current path
// //       console.log(`User Role: ${userRole}`); // Log user role
  
// //       // Set minimization logic based on the user role and path
// //       if (userRole === '[HEALTH_OFFICER]') {
// //         // Minimize if not on the /hovideo page
// //         if (currentPath !== `${BASE_URL}/hovideo`) {
// //           console.log('Minimizing for HEALTH_OFFICER on wrong page');
// //           setIsMinimized(true);
// //         } else {
// //           console.log('Not minimizing for HEALTH_OFFICER on /hovideo');
// //           setIsMinimized(false);
// //         }
// //       } else if (userRole === '[DOCTOR]') {
// //         // Minimize if not on the /legal page
// //         if (currentPath !== `${BASE_URL}/legal`) {
// //           console.log('Minimizing for DOCTOR on wrong page');
// //           setIsMinimized(true);
// //         } else {
// //           console.log('Not minimizing for DOCTOR on /legal');
// //           setIsMinimized(false);
// //         }
// //       } else {
// //         console.log('Unknown user role, not minimizing');
// //         setIsMinimized(false);
// //       }
// //     };
  
// //     // Call the function initially
// //     handleMinimizeBasedOnPath();
  
// //     // Monitor changes to location and user role
// //     return () => {
// //       handleMinimizeBasedOnPath();
// //     };
// //   }, [location.pathname, userRole]);
  

// //   const handleMinimize = () => {
// //     setIsMinimized(!isMinimized);
// //   };

// //   const handleLeaveRoom = () => {
// //     sessionStorage.clear();
// //     if (userRole === '[HEALTH_OFFICER]') {
// //       setTimeout(() => {
// //         navigate('/list');
// //       }, 100);
// //     } else if (userRole === '[DOCTOR]') {
// //       setTimeout(() => {
// //         navigate('/legal');
// //       }, 100);
// //     } else {
// //       console.error('Unknown user role:', userRole);
// //     }
// //   };

// //   return (
// //     <>
// //       <div
// //         ref={videoCallContainer}
// //         className={isMinimized ? 'minimized-video' : 'full-video'}
// //         style={{ width: isMinimized ? '200px' : '100%', height: isMinimized ? '100px' : '500px' }}
// //       >
// //         {isMinimized ? <p>Video Minimized</p> : <p>Video Active</p>}
// //       </div>
// //       <button onClick={handleMinimize} className="minimize-button">
// //         {isMinimized ? (
// //           <FontAwesomeIcon icon={faExpandAlt} />
// //         ) : (
// //           <FontAwesomeIcon icon={faCompressAlt} />
// //         )}
// //       </button>
// //     </>
// //   );
// // };

// // export default PersistentVideoCall;
// import React, { useEffect, useRef, useState } from 'react';
// import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
// import { useLocation, useNavigate } from 'react-router-dom';
// import './Videocallcs.css';
// import { BASE_URL } from '../config/apiConfig';

// const PersistentVideoCall = ({ roomID }) => {
//   const videoCallContainer = useRef(null);
//   const [isMinimized, setIsMinimized] = useState(false);
//   const [userRole, setUserRole] = useState('');
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedUserRole = sessionStorage.getItem('role');
//     setUserRole(storedUserRole);

//     if (!roomID) {
//       console.error('No roomID provided');
//       return;
//     }

//     const appID = 709268713;
//     const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
//     const roomIDString = String(roomID);
//     const Name = sessionStorage.getItem('Name') || 'Doctor';

//     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
//       appID,
//       serverSecret,
//       roomIDString,
//       Date.now().toString(),
//       Name
//     );

//     const zp = ZegoUIKitPrebuilt.create(kitToken);

//     zp.joinRoom({
//       container: videoCallContainer.current,
//       scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
//       turnOnMicrophoneWhenJoining: true,
//       turnOnCameraWhenJoining: true,
//       showMyCameraToggleButton: true,
//       showMyMicrophoneToggleButton: true,
//       showAudioVideoSettingsButton: false,
//       showTextChat: true,
//       showUserList: false,
//       maxUsers: 2,
//       layout: 'Auto',
//       showLayoutButton: false,
//       showPreJoinView: false,
//       showScreenSharingButton: false,
//       onLeaveRoom: () => {
//         handleLeaveRoom();
//       },
//     });

//     return () => {
//       if (zp && zp.destroy) {
//         zp.destroy();
//       }
//     };
//   }, [roomID]);

//   useEffect(() => {
//     const handleMinimizeBasedOnPath = () => {
//       const currentPath = location.pathname;
//       console.log(`Current Path: ${currentPath}`); // Log current path
//       console.log(`User Role: ${userRole}`); // Log user role

//       // Set minimization logic based on the user role and path
//       if (userRole === '[HEALTH_OFFICER]') {
//         // Minimize if not on the /hovideo page
//         if (currentPath !== '/hovideo') {
//           console.log('Minimizing for HEALTH_OFFICER on wrong page');
//           setIsMinimized(true);
//         } else {
//           console.log('Not minimizing for HEALTH_OFFICER on /hovideo');
//           setIsMinimized(false);
//         }
//       } else if (userRole === '[DOCTOR]') {
//         // Minimize if not on the /legal page
//         if (currentPath !== '/legal') {
//           console.log('Minimizing for DOCTOR on wrong page');
//           setIsMinimized(true);
//         } else {
//           console.log('Not minimizing for DOCTOR on /legal');
//           setIsMinimized(false);
//         }
//       } else {
//         console.log('Unknown user role, not minimizing');
//         setIsMinimized(false);
//       }
//     };

//     // Call the function initially
//     handleMinimizeBasedOnPath();

//     // Monitor changes to location and user role
//     return () => {
//       handleMinimizeBasedOnPath();
//     };
//   }, [location.pathname, userRole]);

//   const handleMinimize = () => {
//     setIsMinimized(!isMinimized);
//   };

//   const handleLeaveRoom = () => {
//     // Clear specific items from local storage
//     sessionStorage.removeItem('kitToken');
//     sessionStorage.removeItem('roomID');
//     sessionStorage.removeItem('z_appconfig_709268713_1');
//     sessionStorage.removeItem('z_appconfig_etag_709268713_1');
//     sessionStorage.removeItem('z_deviceID');
//     sessionStorage.removeItem('z_net_access_servers_709268713_1');
//     sessionStorage.removeItem('z_rtc_domains_709268713_1');
//     sessionStorage.removeItem('z_s_c_709268713');
//     sessionStorage.removeItem('z_store_keys');
//     sessionStorage.removeItem('callDeclined');
//     sessionStorage.removeItem('patientDetails_3');
//     sessionStorage.removeItem('patientId');
//     sessionStorage.removeItem('Name');
//     sessionStorage.removeItem('patientName');

//     const userRole1 = sessionStorage.getItem('role');
//     console.log('Current userRole:', userRole1);

//     if (userRole1 === '[HEALTH_OFFICER]') {
//       setTimeout(() => {
//         window.location.href = '/list';
//         setTimeout(() => {
//           window.location.reload();
//         }, 100);
//       }, 100);
//     } else if (userRole1 === '[DOCTOR]') {
//       sessionStorage.removeItem('userId');
//       setTimeout(() => {
//         window.location.href = '/legal';
//         setTimeout(() => {
//           window.location.reload();
//         }, 100);
//       }, 100);
//     } else {
//       console.error('Unknown user role:', userRole);
//     }
//   };

//   return (
//     <>
//       <div
//         ref={videoCallContainer}
//         className={isMinimized ? 'minimized-video' : 'full-video'}
//         style={{ width: isMinimized ? '200px' : '100%', height: isMinimized ? '100px' : '500px' }}
//       >
//         {isMinimized ? <p>Video Minimized</p> : <p>Video Active</p>}
//       </div>
//       <button onClick={handleMinimize} className="minimize-button">
//         {isMinimized ? (
//           <FontAwesomeIcon icon={faExpandAlt} />
//         ) : (
//           <FontAwesomeIcon icon={faCompressAlt} />
//         )}
//       </button>
//     </>
//   );
// };

// export default PersistentVideoCall;

//=================================== final-code ===================================================

import React, { useEffect, useRef, useState } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useLocation } from 'react-router-dom';
import './Videocallcs.css';
import { BASE_URL } from '../config/apiConfig';

const PersistentVideoCall = ({ roomID }) => {
  const videoCallContainer = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [userRole, setUserRole] = useState('');
  const location = useLocation();

  useEffect(() => {
    const storedUserRole = sessionStorage.getItem('role');
    setUserRole(storedUserRole);

    if (!roomID) {
      console.error('No roomID provided');
      return;
    }
    //======old appid and serversecret==============================
    // const appID = 709268713;
    // const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
    //=======================new appid and serversecret====================================
    const appID = 806265494;
    const serverSecret = 'bb374867312339c0c45fdb126e20ad1f';
    const roomIDString = String(roomID);
    const Name = sessionStorage.getItem('Name') || 'Doctor';

    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomIDString,
      Date.now().toString(),
      Name
    );

    const zp = ZegoUIKitPrebuilt.create(kitToken);

    zp.joinRoom({
      container: videoCallContainer.current,
      scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
      turnOnMicrophoneWhenJoining: true,
      turnOnCameraWhenJoining: true,
      showMyCameraToggleButton: true,
      showMyMicrophoneToggleButton: true,
      showAudioVideoSettingsButton: false,
      showTextChat: true,
      showUserList: false,
      maxUsers: 2,
      layout: 'Auto',
      showLayoutButton: false,
      showPreJoinView: false,
      showScreenSharingButton: false,
      onLeaveRoom: () => {
        handleLeaveRoom();
      },
    });

    return () => {
      if (zp && zp.destroy) {
        zp.destroy();
      }
    };
  }, [roomID]);

  useEffect(() => {
    const handleMinimizeBasedOnPath = () => {
      const currentPath = location.pathname;
      console.log(`Current Path: ${currentPath}`); 
      console.log(`User Role: ${userRole}`);
      if (userRole === '[HEALTH_OFFICER]') {

        if (currentPath !== '/hovideo') {
          console.log('Minimizing for HEALTH_OFFICER on wrong page');
          setIsMinimized(true);
        } else {
          console.log('Not minimizing for HEALTH_OFFICER on /hovideo');
          setIsMinimized(false);

        }
      } else if (userRole === '[DOCTOR]') {
    
        if (currentPath !== '/legal') {
          console.log('Minimizing for DOCTOR on wrong page');
          setIsMinimized(true);
        } else {
          console.log('Not minimizing for DOCTOR on /legal');
          setIsMinimized(false);
          
        }
      } else {
        console.log('Unknown user role, not minimizing');
        setIsMinimized(false);
      }
    };
    handleMinimizeBasedOnPath();
    return () => {
      handleMinimizeBasedOnPath();
    };
  }, [location.pathname, userRole]);

  const handleLeaveRoom = () => {
    sessionStorage.removeItem('kitToken');
    sessionStorage.removeItem('roomID');
    sessionStorage.removeItem('z_appconfig_709268713_1');
    sessionStorage.removeItem('z_appconfig_etag_709268713_1');
    sessionStorage.removeItem('z_deviceID');
    sessionStorage.removeItem('z_net_access_servers_709268713_1');
    sessionStorage.removeItem('z_rtc_domains_709268713_1');
    sessionStorage.removeItem('z_s_c_709268713');
    sessionStorage.removeItem('z_store_keys');
    sessionStorage.removeItem('callDeclined');
    sessionStorage.removeItem('patientDetails_3');
    sessionStorage.removeItem('patientId');
    sessionStorage.removeItem('Name');
    sessionStorage.removeItem('patientName');
   


    const userRole1 = sessionStorage.getItem('role');
    console.log('Current userRole:', userRole1);
    if (userRole1 === '[HEALTH_OFFICER]') {
      setTimeout(() => {
        window.location.href = '/list';
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }, 100);
    } else if (userRole1 === '[DOCTOR]') {
      sessionStorage.removeItem('userId');
      setTimeout(() => {
        window.location.href = '/legal';
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }, 100);
    } else {
      console.error('Unknown user role:', userRole);

    }
  };




  return (
    <div
      ref={videoCallContainer}
      className={isMinimized ? 'minimized-video' : 'full-video'}
      style={{ width: isMinimized ? '200px' : '100%', height: isMinimized ? '100px' : '500px' }}
    >
      {isMinimized ? <p>Video Minimized</p> : <p>Active</p>}
      
    </div>

  );

  <div className='abc'></div>
};

export default PersistentVideoCall;



